import { styled } from "styled-components";
import IconVerificationPending from "../icons/verification_pending.png";
import IconVerificationVerified from "../icons/verification_verified.png";
import IconVerificationUnverified from "../icons/verification_unverified.png";

const VerificationStatusBase = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 16px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
`;

export const VerificationStatusVerifiedWrapper = styled(VerificationStatusBase)`
  color: #3fb608;
  background: #c3fba9;
`;

const VerificationStatusIconWrapper = styled.span`
  margin-right: 4px;
`;

export const VerificationStatusVerified = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <VerificationStatusVerifiedWrapper>
    <VerificationStatusIconWrapper>
      <img src={IconVerificationVerified} alt="Verified" />
    </VerificationStatusIconWrapper>
    {children}
  </VerificationStatusVerifiedWrapper>
);

export const VerificationStatusUnverifiedWrapper = styled(
  VerificationStatusBase
)`
  color: #e43a15;
  background: #ffd1d1;
`;

export const VerificationStatusUnverified = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <VerificationStatusUnverifiedWrapper>
    <VerificationStatusIconWrapper>
      <img src={IconVerificationUnverified} alt="Unverified" />
    </VerificationStatusIconWrapper>
    {children}
  </VerificationStatusUnverifiedWrapper>
);

export const VerificationStatusPendingWrapper = styled(VerificationStatusBase)`
  color: #ed8e00;
  background: #fff7b2;
`;

export const VerificationStatusPending = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <VerificationStatusPendingWrapper>
    <VerificationStatusIconWrapper>
      <img src={IconVerificationPending} alt="Pending" />
    </VerificationStatusIconWrapper>
    {children}
  </VerificationStatusPendingWrapper>
);
