import { useQuery } from "@apollo/client";
import { GET_LANGUAGES } from "../queries";
import { Language } from "../types/KolProfile";
import { keyBy } from "lodash";

export const useLanguages = () => {
  const { data } = useQuery<{ language: Language[] }>(GET_LANGUAGES);

  const languages = Array.from(
    (data?.language || []).map((x) => ({
      code: x.code,
      name: x.title,
    }))
  );

  if (languages.length) {
    languages?.sort((a, b) => a.name.localeCompare(b.name));
  }

  function getLanguageByCode(code: string) {
    const languageByCode = keyBy(languages, "code");

    return languageByCode[code];
  }

  return { languages, getLanguageByCode };
};
