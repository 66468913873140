import { styled } from "styled-components";

export const TabGroup = styled.ul`
  display: flex;
  list-style: none;
  justify-content: space-between;

  font-family: CerebriSans;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
`;

type TabItemProps = {
  selected?: boolean;
};

export const TabItem = styled.li<TabItemProps>`
  padding: ${12 / 16}rem 0;
  font-weight: 500;
  text-align: center;
  color: ${(p) => p.selected && "var(--primary-primary-2, #7b73f4)"};
  cursor: pointer;
  border-bottom: ${(p) =>
    p.selected && "2px solid var(--primary-primary-2, #7b73f4)"};
`;
