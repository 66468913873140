import { gql } from "@apollo/client";

export const SAVE_KOL_LANGUAGES = gql`
  mutation saveKolLanguages(
    $objects: [mapping_kol_profile_language_insert_input!]!
  ) {
    insert_mapping_kol_profile_language(objects: $objects) {
      returning {
        language {
          code
          title
        }
      }
    }
  }
`;

export const GET_LANGUAGES = gql`
  query GetLanguages {
    language {
      code
      title
    }
  }
`;

export const DELETE_MAPPING_KOL_LANGUAGE = gql`
  mutation deleteMappingKolLanguage($uuids: [String!]!) {
    delete_mapping_kol_profile_language(
      where: { language_code: { _in: $uuids } }
    ) {
      returning {
        language_code
      }
      affected_rows
    }
  }
`;
