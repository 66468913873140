import styled from "styled-components";
import FilePlusIcon from "../icons/file_plus.png";
import FileCloseIcon from "../icons/file_close.png";
import React, { useState } from "react";
import { ENV } from "../configs/env";

export type UploadInputProps = {
  fileMetadataId: string;
  setFileMetadataId: (value: string, event?: "add" | "remove") => void;
  url?: string;
  name?: string;
};

export const UploadInputDeprecated = (props: UploadInputProps) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [urlRemoved, setUrlRemoved] = useState(false);
  const [internalFileMetadataId, setInternalFileMetadataId] = useState("");
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const uploadImage = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(`${ENV.REACT_APP_WEBHOOKS_BASE_URL}/files`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("uploadImage.networkError");
        }
        return response.json();
      })
      .then((data) => {
        console.log("uploadImage.uploaded:", data);
        props.setFileMetadataId(data?.id);
        setInternalFileMetadataId(data?.id);
      })
      .catch((error) => {
        console.error("uploadImage.error:", error);
      });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log("handleImageChange.file:", file);

    if (!file) {
      console.error("handleImageChange.noFile");
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(reader.result as string);
    };

    reader.readAsDataURL(file);

    uploadImage(file);
  };

  const handleRemoveImage = async () => {
    const metadataId = props.fileMetadataId;

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    if (!metadataId && !props.url && selectedImage) {
      console.log(
        "handleRemoveImage.nonPersistedImage",
        internalFileMetadataId
      );
      props.setFileMetadataId(internalFileMetadataId, "remove");
      setSelectedImage(null);
      setInternalFileMetadataId("");
      return;
    }

    if (props.url && metadataId) {
      console.log("handleRemoveImage.persistedImage", metadataId);
      setUrlRemoved(true);
      props.setFileMetadataId(metadataId, "remove");
      return;
    }

    if (!metadataId) {
      console.error("No file metadata id");
      return;
    }

    setSelectedImage(null);
    props.setFileMetadataId("");
  };

  const imageExists = !!selectedImage || (!!props.url && !urlRemoved);

  return (
    <InternalUploadInputWrapper>
      <UploadInputPlusIconWrapper isHidden={imageExists}>
        <img src={FilePlusIcon} alt="plus icon" />
      </UploadInputPlusIconWrapper>
      <UploadInputCore
        ref={fileInputRef}
        type="file"
        onChange={handleImageChange}
      />
      <UploadInputCloseIconWrapper isHidden={!imageExists}>
        <img src={FileCloseIcon} alt="close icon" onClick={handleRemoveImage} />
      </UploadInputCloseIconWrapper>
      {selectedImage && (
        <img
          src={selectedImage as string}
          alt="Selected"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      )}
      {!selectedImage && props.url && !urlRemoved && (
        <img
          src={props.url}
          alt="Selected"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      )}
    </InternalUploadInputWrapper>
  );
};

const InternalUploadInputWrapper = styled.span`
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px dashed var(--gray-gray-400, #949494);
  position: relative;

  &:hover {
    border: 3px dashed var(--gray-gray-400, #949494);
    cursor: pointer;
  }
`;

const UploadInputPlusIconWrapper = styled.span<{ isHidden?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  ${(p) => p.isHidden && "display: none;"}
`;

const UploadInputCloseIconWrapper = styled.span<{ isHidden?: boolean }>`
  ${(p) => p.isHidden && "display: none;"}
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: var(--bg-darkmode, #181a1c);
  opacity: 0.800000011920929;
  mix-blend-mode: multiply;
  border-radius: 50%;
  padding: 4px;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  ${InternalUploadInputWrapper}:hover & {
    top: -10px;
    right: -10px;
  }
`;

const UploadInputCore = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;

  &:hover {
    cursor: pointer;
  }
`;

export const UploadInputDeprecatedWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  margin: 16px 0;
`;
