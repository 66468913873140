import { styled } from "styled-components";
import { AppLabel } from "./Label";
import { Fragment } from "react";
import { ValidationError } from "./Errors";

type AppTextareaProps = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
};

export const AppTextarea = (props: AppTextareaProps) => {
  return (
    <div>
      {props.label && (
        <Fragment>
          <AppLabel>{props.label}</AppLabel>
          <br />
        </Fragment>
      )}
      <Textarea
        value={props.value}
        onChange={(e: any) => props.onChange(e?.target?.value)}
        disabled={props.disabled}
      />
      {props.error && <ValidationError>{props.error}</ValidationError>}
    </div>
  );
};

const Textarea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #949494;
`;
