import { isAfter } from "date-fns";
import { KolFileMetadata, KolProfile } from "../types/KolProfile";

export const extractLatestAvatar = (kolProfile: KolProfile) => {
  return (
    (kolProfile.kol_file_metadata || [])
      ?.filter((x) => x.type === "avatar")
      .reduce<KolFileMetadata | null>(
        (acc, cur) =>
          !acc || isAfter(new Date(cur.created_at), new Date(acc.created_at))
            ? cur
            : acc,
        null
      )?.file_metadata?.url || ""
  );
};

export const extractBankDetails = (kolProfile: KolProfile) => {
  const list = (kolProfile.kol_file_metadata || [])?.filter(
    (x) => x.type === "bank_details"
  );
  return {
    bankDetailUploadsList: list,
    bankDetailUploadsSet: new Set(list.map((x) => x.file_metadata?.id)),
  };
};

export const extractSocialPlatform = (
  kolProfile: KolProfile,
  type: "INSTAGRAM" | "TIKTOK"
) => {
  const list = (kolProfile.social_platforms || [])?.filter(
    (x) => x.social_platform_code === type
  );

  if (list.length === 0) {
    return null;
  }

  // Assuming that there is only one social platform per type
  return list[0];
};
