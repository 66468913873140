import { createContext, useContext } from "react";

export const HasuraUserContext = createContext<null | string>(null);

export const useHasuraUserId = () => {
  const context = useContext(HasuraUserContext);
  //   if (!context) {
  //     throw new Error("useHasuraUserId must be used within a HasuraUserProvider");
  //   }
  return context;
};
