import { styled } from "styled-components";
import IconCompanyLogo from "../icons/nav_logo_svg.svg";
import IconLogout from "../icons/nav_logout.png";
import { useAuth0 } from "@auth0/auth0-react";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { AppPrimaryButton, AppSecondaryButton } from "../components/Button";
import LogoutLogo from "../icons/logout_logo.png";

const ListWrapper = styled.ul`
  display: flex;
  gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  align-items: center;

  & li:last-child {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 500px;
  padding: 24px 0;
`;

export const Navbar = () => {
  const { isAuthenticated, logout } = useAuth0();

  return (
    <Wrapper>
      <ListWrapper>
        <li>
          <img src={IconCompanyLogo} alt="KolsAsiaLogo" />
        </li>

        {isAuthenticated && (
          <li /*onClick={() => logout()}*/>
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <img src={IconLogout} alt="Logout" />
              </AlertDialog.Trigger>
              <AlertDialog.Content style={{ maxWidth: 400 }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={LogoutLogo} alt="Logout Logo" />
                </div>
                <AlertDialog.Title mt={"5"}>Come back soon!</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure you want to log out of KOLS ?
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="between">
                  <AlertDialog.Cancel>
                    <AppSecondaryButton>Cancel</AppSecondaryButton>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <AppPrimaryButton
                      onClick={() =>
                        logout({
                          logoutParams: {
                            returnTo: window.location.origin,
                          },
                        })
                      }
                    >
                      Log out
                    </AppPrimaryButton>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          </li>
        )}
      </ListWrapper>
    </Wrapper>
  );
};
