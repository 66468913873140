import { styled } from "styled-components";

export const AppMainHeading = styled.h1`
  color: #000;
  font-family: CerebriSans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
`;

export const AppSubHeading = styled.h2`
  color: #000;
  font-family: CerebriSans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;
