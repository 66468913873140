import { gql } from "@apollo/client";

export const SAVE_KOL_FILES = gql`
  mutation saveKolFiles($objects: [kol_file_metadata_insert_input!]!) {
    insert_kol_file_metadata(objects: $objects) {
      returning {
        file_metadata_id
        kol_profile_id
        type
      }
    }
  }
`;

export const DELETE_KOL_FILES = gql`
  mutation deleteKolFiles($uuids: [uuid!]!) {
    delete_kol_file_metadata(where: { file_metadata_id: { _in: $uuids } }) {
      returning {
        file_metadata_id
      }
      affected_rows
    }
  }
`;

export const GET_KOL_LATEST_AVATAR = gql`
  query GetLatestAvatar {
    kol_file_metadata(
      limit: 1
      order_by: { created_at: desc }
      where: { type: "avatar" }
    ) {
      file_metadata {
        id
        url
      }
    }
  }
`;
