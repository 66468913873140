import { gql } from "@apollo/client";

export const GET_CURRENCIES = gql`
  query GetCurrencyCats {
    currency_category {
      code
      symbol
      name
      fraction_size
      template
    }
  }
`;
