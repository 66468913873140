import { parse, format } from "date-fns";

export const dobBreakdown = (dob: string) => {
  const [year, month, day] = dob.split("-");
  return {
    year: parseInt(year),
    month: parseInt(month),
    day: parseInt(day),
  };
};

export type dateAppProps = {
  year: number | string;
  month: number | string;
  day: number | string;
};

export const buildDate = (props: dateAppProps): string => {
  const dateOfBirth = `${props.year}-${props.month}-${props.day}`;
  console.log(`buildDate dateOfBirth: ${dateOfBirth}`);

  let result = parse(dateOfBirth, "yyyy-M-d", new Date());

  if (result.toString() === "Invalid Date") {
    console.error(`Invalid date: ${dateOfBirth}`);
    result = new Date(); // fallback value
  }

  return format(result, "yyyy-MM-dd");
};

export const isValidDate = (props: dateAppProps): null | Date => {
  const dateOfBirth = `${props.year}-${props.month}-${props.day}`;

  let result = parse(dateOfBirth, "yyyy-M-d", new Date());

  if (result.toString() === "Invalid Date") {
    return null;
  }

  return result;
};
