import { styled } from "styled-components";
import IconBackBtn from "../icons/Back.png";

export const BackButtonWrapper = styled.button`
  border: none;
  background: var(--gray-white, #fff);
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const BackButton = (props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  return (
    <BackButtonWrapper {...props}>
      <img src={IconBackBtn} alt="Back" />
    </BackButtonWrapper>
  )
}