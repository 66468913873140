import { useQuery } from "@apollo/client";
import { GET_COUNTRIES } from "../queries";
import { Country } from "../types/KolProfile";
import { keyBy } from "lodash";

export const useCountries = () => {
  const { data: countriesData } = useQuery<{ country_category: Country[] }>(
    GET_COUNTRIES
  );

  const countries = (countriesData?.country_category || []).map((x) => ({
    code: x.code,
    name: x.name,
  }));

  function getCountryByCode(code: string) {
    const countryByCode = keyBy(countries, "code");

    return countryByCode[code];
  }

  const phoneCountryCodes = Array.from(
    new Set(
      (countriesData?.country_category || []).slice().map((x) => x.phone_code)
    )
  );

  if (phoneCountryCodes.length) {
    phoneCountryCodes?.sort((a, b) => a - b);
  }

  return { phoneCountryCodes, countries, getCountryByCode };
};
