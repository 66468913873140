import { useQuery } from "@apollo/client";
import { keyBy } from "lodash";
import { GET_PRODUCT_CATS } from "../queries";

export const useProductCategories = () => {
  const { data } = useQuery<{
    product_category: {
      code: string;
      name: string;
      is_active: number;
    }[];
  }>(GET_PRODUCT_CATS);

  const productCategories = (data?.product_category || []).map((x) => ({
    code: x.code,
    name: x.name,
  }));

  if (productCategories.length) {
    productCategories?.sort((a, b) => a.name.localeCompare(b.name));
  }

  function getCategoryByCode(code: string) {
    const categoryByCode = keyBy(productCategories, "code");

    return categoryByCode[code];
  }

  return {
    productCategories,
    getCategoryByCode,
  };
};
