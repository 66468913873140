import styled from "styled-components";
import PhotoUploadExample from "../icons/upload_example.png";
import * as Popover from "@radix-ui/react-popover";
import IconUploadInfo from "../icons/file_info.png";
import { UnstyledButton } from "../components/Button";

const Wrapper = styled.div`
  background-color: var(--gray-white, #fff);
  padding: 16px;
  border-radius: 16px;
  background: var(--gray-white, #fff);
`;

const Header = styled.h2`
  color: var(--gray-black, #000);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
`;

const DescWrapper = styled.div`
  color: var(--gray-gray-500, #3e3e3e);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;

  & ul {
    padding-left: 22px;
  }
`;

const ImgWrapper = styled.div`
  border: 1px solid var(--gray-gray-300, #d9d9d9);
  cursor: pointer;
`;

export const UploadNotice = () => {
  return (
    <Wrapper>
      <Header>Photo Requirement</Header>
      <DescWrapper>
        <p>
          Upload one or more photos from your bank details that contain the
          following information:
        </p>
        <ul>
          <li>Your bank’s name and address</li>
          <li>Your name and address</li>
          <li>Your bank account number</li>
        </ul>
        <br />
        <p>Example as below:</p>
      </DescWrapper>

      <ImgWrapper>
        <img alt="Upload Example" width={"100%"} src={PhotoUploadExample} />
      </ImgWrapper>
    </Wrapper>
  );
};

export const UploadNoticePopover = () => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild={true}>
        <UnstyledButton>
          <img width={28} height={28} src={IconUploadInfo} alt="Upload Info" />
        </UnstyledButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          style={{
            width: 360,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: 16,
          }}
          sideOffset={5}
        >
          <UploadNotice />
          <Popover.Arrow className="PopoverArrow" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
