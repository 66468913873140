import { useEffect, useMemo, useState } from "react";
import { KolProfile } from "../types/KolProfile";
import { CardWrapper } from "./CardWrapper";
import { GenderCode } from "./CustomRadio";
import { UploadInputDeprecatedWrapper } from "./UploadInput";
import { styled } from "styled-components";
import { AvatarUpload } from "./AvatarUpload";
import { useMutation } from "@apollo/client";
import {
  CREATE_SOCIAL_MAPPING,
  DELETE_KOL_FILES,
  DELETE_KOL_PROD_CATEGORIES,
  DELETE_MAPPING_KOL_LANGUAGE,
  SAVE_KOL_FILES,
  SAVE_KOL_LANGUAGES,
  SAVE_KOL_PROD_CATS,
  UPDATE_KOL_PROFILE,
} from "../queries";
import { findDiff } from "../utils";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  LabelTopTextInput,
  TextInput,
  TextInputWrapper,
} from "../components/TextInput";
import { AppRadioGroup } from "../components/RadioInput";
import { isInvalid } from "../utils/validation";
import {
  AppDropdownMulti,
  AppDropdownSingle,
  AppDropdownSingleWrapper,
} from "../components/DropdownInput";
import { DateOfBirth } from "../core/DateOfBirth";
import { useCountries } from "../hooks/useCountries";
import { useCurrencies } from "../hooks/useCurrencies";
import { AppTextarea } from "../components/TextareaInput";
import { CurrencyInput } from "../components/NumericInput";
import { useProductCategories } from "../hooks/useProductCategories";
import { buildDate, dobBreakdown } from "../utils/datetime";
import { useLanguages } from "../hooks/useLanguages";
import { AppCheckbox } from "../components/Checkbox";
import {
  extractBankDetails,
  extractLatestAvatar,
  extractSocialPlatform,
} from "../utils/extract";
import {
  AppPrimaryButton,
  AppSecondaryButton,
  SocialButton,
} from "../components/Button";
import { AppMainHeading, AppSubHeading } from "../components/Heading";
import {
  VerificationStatusPending,
  VerificationStatusUnverified,
  VerificationStatusVerified,
} from "../components/VerificationStatus";
import { TabGroup, TabItem } from "../components/Tab";
import { RegularInfo } from "../components/Info";
import { SocialInfo } from "../core/SocialInfo";
import { IncrementalUploads } from "../core/IncrementalUploads";
import { useMetrics } from "../hooks/useMetrics";
import { ENV } from "../configs/env";
import IconEditPencil from "../icons/edit_pencil.png";

import { AlertDialog, Flex } from "@radix-ui/themes";
import { UploadNoticePopover } from "../core/UploadNotice";
import { toast } from "../utils/toast";
import { useFx } from "../hooks/useFx";

type EditViewProps = {
  kolProfile: KolProfile;
  onEvent?: (event: "profileSaved") => void | Promise<void>;
};

enum TabId {
  Information = "information",
  Payment = "payment",
  Social = "social",
}

const TABS = {
  [TabId.Information]: {
    Id: TabId.Information,
    Label: "Information",
  },
  [TabId.Payment]: {
    Id: TabId.Payment,
    Label: "Payment",
  },
  [TabId.Social]: {
    Id: TabId.Social,
    Label: "Social account",
  },
};

export const EditView = (props: EditViewProps) => {
  /////////////////
  ///// STATE /////
  /////////////////
  const [isEditMode, setIsEditMode] = useState(false);

  /////////////////
  ///// QUERY /////
  /////////////////
  const { languages } = useLanguages();
  const { phoneCountryCodes, countries, getCountryByCode } = useCountries();
  const { currencies, getCurrencyByCode } = useCurrencies();
  const { productCategories } = useProductCategories();
  const { data: metricsData } = useMetrics();

  ////////////////////
  ///// MUTATION /////
  ////////////////////
  const [saveKolFiles] = useMutation(SAVE_KOL_FILES);
  const [deleteKolFiles] = useMutation(DELETE_KOL_FILES);
  const [saveKolLanguages] = useMutation(SAVE_KOL_LANGUAGES);
  const [deleteKolLanguages] = useMutation(DELETE_MAPPING_KOL_LANGUAGE);
  const [saveKolProdCats] = useMutation(SAVE_KOL_PROD_CATS);
  const [deleteKolProdCats] = useMutation(DELETE_KOL_PROD_CATEGORIES);
  const [updateKolProfile] = useMutation(UPDATE_KOL_PROFILE);
  const [createSocialMapping /*{ data, loading, error }*/] = useMutation(
    CREATE_SOCIAL_MAPPING
  );

  const [avatarUrl] = useState(extractLatestAvatar(props.kolProfile));
  const instagramInfo = extractSocialPlatform(props.kolProfile, "INSTAGRAM");
  const { bankDetailUploadsList: bankDetailUploadsListOriginal } =
    useMemo(() => {
      return extractBankDetails(props.kolProfile);
    }, [props.kolProfile]);

  const methods = useForm({
    defaultValues: buildInitialFormProps(),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function buildInitialFormProps() {
    return {
      /////////////////////////////////
      ///////// Information ///////////
      /////////////////////////////////
      avatarMetaId: "", // newly uploaded avatar
      firstName: props.kolProfile.first_name || "",
      lastName: props.kolProfile.last_name || "",
      gender: props.kolProfile.gender || "",
      dobYear: dobBreakdown(props.kolProfile.date_of_birth).year || 0,
      dobMonth: dobBreakdown(props.kolProfile.date_of_birth).month || 0,
      dobDay: dobBreakdown(props.kolProfile.date_of_birth).day || 0,
      languages: (props.kolProfile.mapping_kol_profile_languages || []).map(
        (x) => ({
          value: x.language.code,
          label: x.language.title,
        })
      ),
      occupation: props.kolProfile.occupation || "",
      phonePrefix: props.kolProfile.mobile_country_code || "",
      phoneSuffix: props.kolProfile.mobile_number || "",
      country: props.kolProfile.country_code || "",
      addressCity: props.kolProfile.address_city || "",
      addressState: props.kolProfile.address_state || "",
      addressPostalCode: props.kolProfile.address_postal_code || "",
      address: props.kolProfile.address || "",
      currency: props.kolProfile.display_currency || "",
      bio: props.kolProfile.bio || "",
      productCategories: (props.kolProfile.product_categories || []).map(
        (cat) => cat.interest_code
      ),
      pastBrands: props.kolProfile.past_brands_collaboration || "",
      minRatePhoto: props.kolProfile.min_rate_photo_usd_cents || null,
      minRateVid: props.kolProfile.min_rate_video_usd_cents || null,
      /////////////////////////////////
      /////////// Payment /////////////
      /////////////////////////////////
      fullName: props.kolProfile.individual_fullname || "",
      idCardNumber: props.kolProfile.individual_id_no || "",
      bankName: props.kolProfile.bank_name || "",
      bankSwiftCode: props.kolProfile.bank_swift_code || "",
      bankCountry: props.kolProfile.bank_country || "",
      bankCity: props.kolProfile.bank_city || "",
      bankState: props.kolProfile.bank_state || "",
      bankAddress: props.kolProfile.bank_address || "",
      bankAccNo: props.kolProfile.bank_account_no || "",
      bankDetailUploadsAdd: [],
      bankDetailUploadsRemove: [],
    };
  }

  const {
    control,
    watch,
    trigger,
    formState: { errors, isDirty },
    reset,
    setValue,
  } = methods;

  useEffect(() => {
    reset(buildInitialFormProps());
  }, [props.kolProfile, reset]);

  const [currentTabId, setCurrentTabId] = useState<keyof typeof TABS>(
    TABS.information.Id as any
  );

  const onChangeTab = (id: keyof typeof TABS) => {
    setCurrentTabId(id);
  };

  const onSaveClick = async () => {
    const MapTabIdToValidationFields: Record<TabId, string[]> = {
      [TabId.Information]: [
        "firstName",
        "lastName",
        "dobDay",
        "dobMonth",
        "dobYear",
        "occupation",
        "phonePrefix",
        "phoneSuffix",
        "country",
        "currency",
        "addressCity",
        "addressState",
        "addressPostalCode",
        "bio",
        "productCategories",
        "pastBrands",
        "minRatePhoto",
        "minRateVid",
        "avatarMetaId",
        "languages",
      ],
      [TabId.Payment]: [
        "fullName",
        "idCardNumber",
        "bankName",
        "bankSwiftCode",
        "bankCountry",
        "bankCity",
        "bankState",
        "bankAddress",
        "bankAccNo",
        "bankDetailUploadsAdd",
        "bankDetailUploadsRemove",
      ],
      [TabId.Social]: [],
    };

    const validationFields = MapTabIdToValidationFields[currentTabId] || [];

    for (const field of validationFields) {
      await trigger(field as any);
    }

    if (
      isInvalid({
        errors,
        validationFields,
      })
    ) {
      console.info("=================!!!==================");
      console.info("Invalid fields", Object.keys(errors));
      console.error("Errors: ", errors);
      return;
    }

    if (!isDirty) {
      console.info("No changes");
      return;
    }

    try {
      switch (currentTabId) {
        case TabId.Information:
          await saveInformation();
          toast.success("Information saved");
          setIsEditMode(false);
          break;
        case TabId.Payment:
          await savePayments();
          toast.success("Payment details saved");
          setIsEditMode(false);
          break;
        case TabId.Social:
          break;
      }

      if (props.onEvent) {
        await props.onEvent("profileSaved");
      }
    } catch (error) {
      console.error("Error saving profile: ", error);
    }
  };

  const saveInformation = async () => {
    const langDiff = findDiff(
      (props.kolProfile.mapping_kol_profile_languages || []).map(
        (x) => x.language.code
      ),
      watch("languages").map((x) => x.value)
    );

    const saveLanguages = await Promise.all([
      saveKolLanguages({
        variables: {
          objects: langDiff.toAdd.map((x) => ({
            kol_profile_id: props.kolProfile.id,
            language_code: x,
          })),
        },
      }),
      deleteKolLanguages({
        variables: {
          uuids: langDiff.toDelete,
        },
      }),
    ]);

    console.log("saveInformation.saveLanguages: ", saveLanguages);

    // AVATAR
    if (watch("avatarMetaId")) {
      const fileMetadataInput = [
        {
          kol_profile_id: props.kolProfile.id,
          file_metadata_id: watch("avatarMetaId"),
          type: "avatar",
        },
      ];

      const saveKolFilesRes = await saveKolFiles({
        variables: {
          objects: fileMetadataInput,
        },
      });
      console.log("saveKolFiles: ", saveKolFilesRes);
    }

    // PRODUCT CATEGORIES
    const prodCatDiff = findDiff(
      props.kolProfile.product_categories.map((x) => x.interest_code),
      Array.from(watch("productCategories"))
    );

    const saveKolProdCatsRes = await Promise.all([
      saveKolProdCats({
        variables: {
          objects: prodCatDiff.toAdd.map((interestCode) => ({
            kol_id: props.kolProfile.id,
            interest_code: interestCode,
          })),
        },
      }),
      deleteKolProdCats({
        variables: {
          uuids: prodCatDiff.toDelete,
        },
      }),
    ]);

    console.log("saveInformation.saveKolProdCatsRes: ", saveKolProdCatsRes);

    // UPDATE KOL PROFILE
    const updateKolProfileRes = await updateKolProfile({
      variables: {
        id: props.kolProfile.id,
        objects: {
          first_name: watch("firstName"),
          last_name: watch("lastName"),
          gender: watch("gender"),
          date_of_birth: buildDate({
            day: watch("dobDay"),
            month: watch("dobMonth"),
            year: watch("dobYear"),
          }),
          occupation: watch("occupation"),
          mobile_country_code: watch("phonePrefix"),
          mobile_number: watch("phoneSuffix"),
          country_code: watch("country"),
          address_city: watch("addressCity"),
          address_state: watch("addressState"),
          address_postal_code: watch("addressPostalCode"),
          address: watch("address"),
          display_currency: watch("currency"),
          bio: watch("bio"),
          past_brands_collaboration: watch("pastBrands"),
          min_rate_photo_usd_cents: watch("minRatePhoto") * 100, // convert to cents
          min_rate_video_usd_cents: watch("minRateVid") * 100, // convert to cents
        },
      },
    });

    console.log("saveInformation.updateKolProfile: ", updateKolProfileRes);
  };

  const savePayments = async () => {
    const result = await updateKolProfile({
      variables: {
        id: props.kolProfile.id,
        objects: {
          individual_fullname: watch("fullName"),
          individual_id_no: watch("idCardNumber"),
          bank_name: watch("bankName"),
          bank_swift_code: watch("bankSwiftCode"),
          bank_country: watch("bankCountry"),
          bank_city: watch("bankCity"),
          bank_state: watch("bankState"),
          bank_address: watch("bankAddress"),
          bank_account_no: watch("bankAccNo"),
        },
      },
    });

    console.log("savePayments.updateKolProfile: ", result);

    const adds = watch("bankDetailUploadsAdd");
    const removes = watch("bankDetailUploadsRemove");

    const promises = [];
    if (adds.length > 0) {
      promises.push(
        saveKolFiles({
          variables: {
            objects: adds.map((fileMetadataId) => ({
              kol_profile_id: props.kolProfile.id,
              file_metadata_id: fileMetadataId,
              type: "bank_details",
            })),
          },
        })
      );
    }
    if (removes.length > 0) {
      promises.push(
        deleteKolFiles({
          variables: {
            uuids: removes,
          },
        })
      );
    }

    const saveKolFilesRes = await Promise.all(promises);

    console.log("savePayments.saveKolFiles", saveKolFilesRes);
  };

  const renderTabInformation = () => {
    return (
      <TabInformationWrapper>
        <AppSubHeading>Basic Information</AppSubHeading>
        <Controller
          name="avatarMetaId"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <AvatarUpload
              fileMetadataId={field.value}
              setFileMetadataId={field.onChange}
              url={avatarUrl}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="firstName"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="firstName"
              label="First name"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="lastName"
              label="Last name"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="gender"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <AppRadioGroup
              disabled={!isEditMode}
              error={error?.message}
              label="Gender"
              value={field.value}
              onChange={(x) => field.onChange(x)}
              valuesList={[
                {
                  id: "male",
                  value: GenderCode.MALE,
                  label: "Male",
                },
                {
                  id: "female",
                  value: GenderCode.FEMALE,
                  label: "Female",
                },
                {
                  id: "others",
                  value: GenderCode.OTHERS,
                  label: "Others",
                },
              ]}
            />
          )}
        />

        <DateOfBirth control={control} watch={watch} disabled={!isEditMode} />

        <Controller
          name="languages"
          control={control}
          rules={{
            required: "Field is required",
            minLength: { value: 1, message: "Select at least 1" },
          }}
          render={({ field, fieldState: { error } }) => (
            <AppDropdownMulti
              label="Spoken languages"
              options={languages.map((x) => ({
                value: x.code,
                label: x.name,
              }))}
              selectedOptions={field.value}
              onChange={(x) => field.onChange(x)}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />

        <Controller
          name="occupation"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="occupation"
              label="Occupation"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />

        <AppSubHeading>Address</AppSubHeading>
        <MobileWrapper>
          <Controller
            name="phonePrefix"
            control={control}
            rules={{ required: "Field is required" }}
            render={({ field, fieldState: { error } }) => (
              <AppDropdownSingle
                id="phonePrefix"
                options={phoneCountryCodes.map((x) => ({
                  value: x.toString(),
                  label: `+${x}`,
                }))}
                placeholder="Select"
                onChange={(x) => field.onChange(x)}
                error={error?.message}
                value={{
                  value: watch("phonePrefix"),
                  label: `+${watch("phonePrefix")}`,
                }}
                disabled={!isEditMode}
              />
            )}
          />
          <Controller
            name="phoneSuffix"
            control={control}
            rules={{ required: "Field is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="phoneSuffix"
                label="Mobile number"
                onChange={(x) => field.onChange(x)}
                value={field.value}
                error={error?.message}
                disabled={!isEditMode}
              />
            )}
          />
        </MobileWrapper>

        <TextInput
          id="email"
          label="Email"
          onChange={(x) => {}}
          value={props.kolProfile.user.email}
          disabled={true}
        />
        <Controller
          name="country"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <AppDropdownSingle
              id="country"
              options={countries.map((x) => ({
                value: x.code,
                label: x.name,
              }))}
              placeholder="Country"
              onChange={(x) => field.onChange(x)}
              error={error?.message}
              value={{
                value: watch("country"),
                label: getCountryByCode(watch("country"))?.name || "",
              }}
              label="Country"
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="addressCity"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="addressCity"
              label="City"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="addressState"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="addressState"
              label="State"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="address"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="address"
              label="Address"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="addressPostalCode"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="addressPostalCode"
              label="Postal code"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />

        <Controller
          name="currency"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <AppDropdownSingle
              id="currency"
              options={currencies.map((x) => ({
                value: x.code,
                label: x.name,
              }))}
              onChange={(x) => field.onChange(x)}
              error={error?.message}
              value={{
                value: watch("currency"),
                label: getCurrencyByCode(watch("currency"))?.name || "",
              }}
              label="Currency display"
              disabled={!isEditMode}
            />
          )}
        />
        <AppSubHeading>Profile</AppSubHeading>

        <Controller
          name="bio"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <AppTextarea
              label="Bio"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />

        {/* Interests */}
        <AppCheckbox
          name="productCategories"
          options={productCategories.map((x) => ({
            code: x.code,
            name: x.name,
          }))}
          control={control}
          selectedOptions={watch("productCategories")}
          disabled={!isEditMode}
        />
        <Controller
          name="pastBrands"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <LabelTopTextInput
              id="pastBrands"
              label="Brands you've worked with in the past"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="minRatePhoto"
          control={control}
          rules={{ required: "Field is required", min: 0 }}
          render={({ field, fieldState: { error } }) => (
            <CurrencyInput
              id="minRatePhoto"
              label="Min rate per photo post"
              onChange={(x) => field.onChange(x)}
              value={
                typeof field.value === "number"
                  ? field.value / 100
                  : field.value
              }
              error={error?.message}
              disabled={!isEditMode}
              symbol={"US $"}
            />
          )}
        />
        <Controller
          name="minRateVid"
          control={control}
          rules={{ required: "Field is required", min: 0 }}
          render={({ field, fieldState: { error } }) => (
            <CurrencyInput
              id="minRateVid"
              label="Min rate per video post"
              onChange={(x) => field.onChange(x)}
              value={
                typeof field.value === "number"
                  ? field.value / 100
                  : field.value
              }
              error={error?.message}
              disabled={!isEditMode}
              symbol={"US $"}
            />
          )}
        />
      </TabInformationWrapper>
    );
  };

  const renderVerificationStatus = () => {
    switch (String(props.kolProfile.bank_verification_status).toLowerCase()) {
      case "verified":
        return (
          <VerificationStatusVerified>
            Your payment information was verified
          </VerificationStatusVerified>
        );
      case "rejected":
        return (
          <VerificationStatusUnverified>
            Your payment information was not verified
          </VerificationStatusUnverified>
        );
      case "pending":
      case "null":
      case "undefined":
        return (
          <VerificationStatusPending>
            Your payment information is pending verification
          </VerificationStatusPending>
        );
      default:
    }
  };

  const renderTabPayment = () => {
    return (
      <TabPaymentWrapper>
        <AppSubHeading>Personal information</AppSubHeading>
        {renderVerificationStatus()}
        <Controller
          name="fullName"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="fullName"
              label="Full name"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />

        <Controller
          name="idCardNumber"
          control={control}
          rules={{
            required: "Field is required",
            pattern: {
              message:
                "ID card number could not contain special characters( -, /, &, *, +)",
              value: /^[^-,/&*+]+$/,
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="idCardNumber"
              label="ID card number"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />

        <AppSubHeading>Bank Details</AppSubHeading>
        <Controller
          name="bankName"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="bankName"
              label="Bank name"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="bankSwiftCode"
          control={control}
          rules={{
            required:
              watch("bankCountry") === "MY"
                ? false
                : "Enter the SWIFT code for the bank",
          }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="bankSwiftCode"
              label="Bank SWIFT code"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              info="Optional for banks in Malaysia"
              disabled={!isEditMode}
            />
          )}
        />
        <AppSubHeading>Bank address</AppSubHeading>

        <Controller
          name="bankCountry"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <AppDropdownSingle
              id="bankCountry"
              options={countries.map((x) => ({
                value: x.code,
                label: x.name,
              }))}
              placeholder="Country"
              onChange={(x) => field.onChange(x)}
              error={error?.message}
              value={{
                value: watch("bankCountry"),
                label: getCountryByCode(watch("bankCountry"))?.name || "",
              }}
              label="Country"
              disabled={!isEditMode}
            />
          )}
        />

        <Controller
          name="bankCity"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="bankCity"
              label="City"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="bankState"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="bankState"
              label="State"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="bankAddress"
          control={control}
          rules={{ required: "Field is required" }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="bankAddress"
              label="Address"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <Controller
          name="bankAccNo"
          control={control}
          rules={{
            required: "Field is required",
            pattern: {
              message:
                "ID card number could not contain special characters( -, /, &, *, +)",
              value: /^[^-,/&*+]+$/,
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="bankAccNo"
              label="Bank Acc No"
              onChange={(x) => field.onChange(x)}
              value={field.value}
              error={error?.message}
              disabled={!isEditMode}
            />
          )}
        />
        <AppSubHeading
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ maxWidth: "80%" }}>
            <span style={{ marginBottom: 8 }}>Bank Details Upload</span>
            <RegularInfo>
              Required photo have information of Bank account(Bank name, Bank
              address, Account Number)
            </RegularInfo>
          </div>
          <UploadNoticePopover />
        </AppSubHeading>

        <UploadInputDeprecatedWrapper>
          <IncrementalUploads
            disabled={!isEditMode}
            bankDetailUploadsList={bankDetailUploadsListOriginal}
            onItemsChanged={(items) => {
              const newMetadataIds = items
                .filter((x) => !!x.fileMetadataId)
                .map((x) => x.fileMetadataId);

              const originalMetadataIds = bankDetailUploadsListOriginal.map(
                (x) => x.file_metadata.id
              );

              const diff = findDiff(originalMetadataIds, newMetadataIds);

              const add = diff.toAdd;
              const remove = diff.toDelete;

              // console.log("+++ add: ", add);
              // console.log("--- remove: ", remove);

              setValue("bankDetailUploadsAdd", add as never[], {
                shouldDirty: true,
              });
              setValue("bankDetailUploadsRemove", remove as never[], {
                shouldDirty: true,
              });
            }}
          />
        </UploadInputDeprecatedWrapper>
      </TabPaymentWrapper>
    );
  };

  const renderTabSocial = () => {
    return (
      <SocialButtonsWrapper>
        <SocialButton variant="tiktok">Connect to Tiktok</SocialButton>
        {instagramInfo ? (
          <SocialInfo socialInfo={instagramInfo} metricInfo={metricsData} />
        ) : (
          <SocialButton onClick={handleInstagramConnect} variant="instagram">
            Connect to Instagram
          </SocialButton>
        )}
      </SocialButtonsWrapper>
    );
  };

  const handleInstagramConnect = async () => {
    try {
      const { data } = await createSocialMapping({
        variables: {
          object: {
            kol_profile_id: props.kolProfile.id,
            social_platform_code: "INSTAGRAM",
            // platform_username: "taylorswift", /* TESTING METRICS SVC */
          },
        },
      });
      console.log("createSocialMapping: ", data);
      const mappingId = data?.insert_kol_social_platform_one?.id;

      if (!mappingId) {
        console.error(`missing social mapping id`);
        return;
      }

      window.location.href = `${ENV.REACT_APP_WEBHOOKS_BASE_URL}/instagram/authenticate/${mappingId}?successUrl=${document.location.origin}&errorUrl=${document.location.origin}`;
    } catch (err) {
      console.error(err);
    }
  };

  const switchToEditMode = () => {
    setIsEditMode(true);
  };

  const handleCancelChanges = () => {
    console.log("handleCancelChanges");
    setIsEditMode(false);
    reset(buildInitialFormProps());
  };

  return (
    <CardWrapper>
      {/* FormProvider makes useFormContext possible */}
      <FormProvider {...methods}>
        <AppMainHeading
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Edit Profile</span>
          {!isEditMode && (
            <img
              src={IconEditPencil}
              alt="Edit Mode"
              width={32}
              height={32}
              style={{ cursor: "pointer" }}
              onClick={switchToEditMode}
            />
          )}
        </AppMainHeading>

        {/* <IncrementalUploadsTest /> */}

        <TabGroup style={{ marginBottom: "20px" }}>
          {Object.values(TABS).map((tab) => (
            <TabItem
              selected={tab.Id === currentTabId}
              onClick={() => onChangeTab(tab.Id as any)}
              key={tab.Id}
            >
              {tab.Label}
            </TabItem>
          ))}
        </TabGroup>
        {currentTabId === TabId.Information && renderTabInformation()}
        {currentTabId === TabId.Payment && renderTabPayment()}
        {currentTabId === TabId.Social && renderTabSocial()}
        {isEditMode && (
          <ButtonsGroup>
            <AppSecondaryButton onClick={handleCancelChanges}>
              Cancel
            </AppSecondaryButton>
            <AlertDialog.Root>
              <AlertDialog.Trigger>
                <AppPrimaryButton>Save</AppPrimaryButton>
              </AlertDialog.Trigger>
              <AlertDialog.Content style={{ maxWidth: 400 }}>
                <AlertDialog.Title>Save information</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure you want to save the information? This means that
                  you will have to wait for the system to verify your
                  information
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="between">
                  <AlertDialog.Cancel>
                    <AppSecondaryButton>Cancel</AppSecondaryButton>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <AppPrimaryButton onClick={onSaveClick}>
                      OK
                    </AppPrimaryButton>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          </ButtonsGroup>
        )}
      </FormProvider>
    </CardWrapper>
  );
};

const TabInformationWrapper = styled.div`
  & > * {
    margin-bottom: 1rem;
  }
`;

const ButtonsGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const SocialButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  gap: ${24 / 16}rem;
`;

const MobileWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  & > *:last-child {
    flex: 1;
    margin-left: 1rem;
  }
`;

const TabPaymentWrapper = styled.div`
  & ${AppSubHeading} {
    margin-bottom: 1rem;
  }

  & ${AppSubHeading}:not(:first-child) {
    margin-top: 2rem;
  }

  & ${TextInputWrapper} {
    margin-bottom: 14px;
  }

  & ${AppDropdownSingleWrapper} {
    margin-bottom: 14px;
  }
`;
