import IconBackBtn from "../icons/Back.png";
import { styled } from "styled-components";

export const AppButton = styled.button`
  display: flex;
  padding: 11.5px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 100px;
  border: none;
  cursor: pointer;
`;

export const AppPrimaryButton = styled(AppButton)`
  background: #7b73f4;
  color: white;
`;

export const AppSecondaryButton = styled(AppButton)`
  border: 1px solid #7b73f4;
  color: #7b73f4;
  background-color: inherit;
`;

type SocialButtonProps = {
  variant?: "tiktok" | "instagram";
};

export const SocialButton = styled.button<SocialButtonProps>`
  display: flex;
  height: 44px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 100px;
  border: ${(p) =>
    p.variant === "tiktok" ? "1px solid #00F2EA" : "1px solid #E1306C"};
  text-transform: uppercase;
  color: var(--gray-black, #000);
  text-align: center;
  font-family: CerebriSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: inherit;
  cursor: pointer;
`;

export const BackButtonWrapper = styled.button`
  border: none;
  background: var(--gray-white, #fff);
  cursor: pointer;
`;

export const BackButton = (
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) => {
  return (
    <BackButtonWrapper {...props}>
      <img src={IconBackBtn} alt="Back" />
    </BackButtonWrapper>
  );
};

export const UnstyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  appearance: none; /* for some browsers */
  -webkit-appearance: none; /* for Chrome and Safari */
  -moz-appearance: none; /* for Firefox */

  &:hover,
  &:focus,
  &:active {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;
