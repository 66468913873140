import { styled } from "styled-components";
import { AppPrimaryButton, BackButton } from "../components/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "../utils/toast";
import { resendVerificationEmail } from "../utils/auth0";

const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--gray-gray-300, #d9d9d9);
  background: #fff;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 100px;
`;

const VerifyEmailHeading = styled.h1`
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

export const VerifyEmail = () => {
  const { logout, getAccessTokenSilently, user, getIdTokenClaims } = useAuth0();

  // DEBUGGING
  // useEffect(() => {
  //   (async () => {
  //     const idToken = await getIdTokenClaims();
  //     const accessToken = await getAccessTokenSilently({});
  //     console.log("idToken", idToken);
  //     console.log("accessToken", accessToken);
  //     console.log("user", user);
  //   })();
  // }, []);

  const checkEmailVerified = async () => {
    try {
      // This is one way to force auth0 to refresh the user profile
      await getAccessTokenSilently({ cacheMode: "off" });

      const idToken = await getIdTokenClaims();

      console.log("checkEmailVerified user", user);
      console.log("checkEmailVerified idToken", idToken);

      if (idToken?.email_verified) {
        toast.success("Email verified");
        // window.location.href = window.location.origin;
      } else {
        toast.error("Email not yet verified");
      }
    } catch (e: any) {
      console.error("checkEmailVerified error", e);
      // toast.error("Error verifying email " + e?.message);
    }
  };

  const goBack = () => {
    const origin = window.location.origin;

    logout({
      logoutParams: {
        returnTo: origin,
      },
    });

    window.location.href = origin;
  };

  const handleResendEmail = async () => {
    if (!user?.sub) {
      toast.error("Error resending email: no user id");
      return;
    }

    try {
      await resendVerificationEmail(user?.sub);
      toast.success("Email resent");
    } catch (e: any) {
      console.log("handleResendEmail error", e);
      toast.error("Error resending email " + e?.message);
    }
  };

  return (
    <Wrapper>
      <div>
        <BackButton onClick={goBack} />
      </div>
      <div>
        <VerifyEmailHeading>Verify Your Email</VerifyEmailHeading>
        <p style={{ marginBottom: 20 }}>
          An email has been sent to{" "}
          <strong>{user?.email || "your email address"}</strong> with a link to
          verify your account.
        </p>
        <p style={{ marginBottom: 24 }}>
          If you have not received the email?{" "}
          <a href="#" onClick={handleResendEmail}>
            Resend email
          </a>
        </p>
        <ButtonWrapper>
          <AppPrimaryButton onClick={checkEmailVerified}>
            I'm verified, let me in
          </AppPrimaryButton>
        </ButtonWrapper>
      </div>
    </Wrapper>
  );
};
