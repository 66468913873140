import { gql } from "@apollo/client";

export const CREATE_SOCIAL_MAPPING = gql`
  mutation createSocialMapping($object: kol_social_platform_insert_input!) {
    insert_kol_social_platform_one(object: $object) {
      id
      kol_profile_id
      social_platform_code
      platform_username
    }
  }
`;
