import { Fragment, useEffect, useState } from "react";
import { KolFileMetadata } from "../types/KolProfile";
import { UploadInput } from "../components/Upload";

const max = 5;

type IncrementalUploadsProps = {
  bankDetailUploadsList: KolFileMetadata[];
  disabled?: boolean;
  onItemsChanged: (items: ItemProps[]) => void;
};

export enum ItemState {
  Hidden = "hidden",
  VisibleImageNotPersisted = "visibleImageNotPersisted",
  VisibleImagePersisted = "visibleImagePersisted",
  VisiblePlus = "visiblePlus",
}

type ItemProps = {
  fileMetadataId: string;
  url?: string;
  state: ItemState;
};

export const IncrementalUploads = (props: IncrementalUploadsProps) => {
  const [items, setItems] = useState<ItemProps[]>(
    props.bankDetailUploadsList
      .map((item) => ({
        fileMetadataId: item.file_metadata.id,
        url: item.file_metadata.url,
        state: ItemState.VisibleImagePersisted,
      }))
      .concat(
        props.bankDetailUploadsList.length < max
          ? ([
              {
                fileMetadataId: "",
                state: ItemState.VisiblePlus,
              },
            ] as any)
          : ([] as any)
      )
  );

  useEffect(() => {
    console.log(
      "items:",
      items.map((x) => x.state)
    );
    props.onItemsChanged(items);
  }, [items, props]);

  const setFileMetadataId = (
    value: string,
    event?: "add" | "remove",
    index?: number,
    url?: string
  ) => {
    console.log("setFileMetadataId:", value, event, index, url);
    if (event === "add") {
      setItems((items) => {
        const newItems = JSON.parse(JSON.stringify(items)) as ItemProps[];
        const lastIdx = newItems.length - 1;
        newItems[lastIdx].fileMetadataId = value;
        newItems[lastIdx].state = ItemState.VisibleImageNotPersisted;
        newItems[lastIdx].url = url;
        if (newItems.length < max) {
          newItems.push({
            fileMetadataId: "",
            state: ItemState.VisiblePlus,
            url: "",
          });
        }
        return newItems;
      });
    } else if (event === "remove") {
      // Shift all items to the right of the removed item to the left by one
      if (typeof index === "number") {
        setItems((items) => {
          const newItems = JSON.parse(JSON.stringify(items)) as ItemProps[];
          //   console.log("items before:", newItems);
          newItems.splice(index, 1);
          if (newItems[newItems.length - 1].state !== ItemState.VisiblePlus) {
            newItems.push({
              fileMetadataId: "",
              state: ItemState.VisiblePlus,
              url: "",
            });
          }
          //   console.log("items after:", newItems);
          return newItems;
        });
      }
    }
  };

  return (
    <Fragment>
      {items.map((item, index) =>
        props.disabled && item.state === ItemState.VisiblePlus ? null : (
          <UploadInput
            key={item?.fileMetadataId || `plus-${index}`}
            fileMetadataId={item.fileMetadataId}
            setFileMetadataId={setFileMetadataId}
            state={item.state}
            url={item.url}
            index={index}
            disabled={props.disabled}
          />
        )
      )}
    </Fragment>
  );
};
