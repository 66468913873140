import { ENV } from "../configs/env";

export async function resendVerificationEmail(externalUserId: string) {
  const response = await fetch(
    `${ENV.REACT_APP_WEBHOOKS_BASE_URL}/public/resend-email-verification`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        externalUserId,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to send verification email");
  }
  const json = await response.json();

  console.log(`resendVerificationEmail json: ${JSON.stringify(json)}`);
  return json;
}

export const fetchUserProfile = async (userId: string, accessToken: string) => {
  const response = await fetch(
    `https://${ENV.REACT_APP_AUTH0_HOST}/api/v2/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const data = await response.json();

  console.log(`fetchUserProfile data: ${JSON.stringify(data)}`);

  return data;
};
