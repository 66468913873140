import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import styled, { createGlobalStyle } from "styled-components";
import { Fragment, useEffect, useState } from "react";
import { CustomTextInputDeprecated } from "./components_dep/CustomTextInputDeprecated";
import { GenderCode } from "./components_dep/CustomRadio";
import {
  UploadInputDeprecated,
  UploadInputDeprecatedWrapper,
} from "./components_dep/UploadInput";
import { Progress } from "./components_dep/Progress";
import { ProductCats } from "./components_dep/ProductCats";
import { useMutation, useQuery } from "@apollo/client";
import { useHasuraUserId } from "./providers/Hasura";
import { EditView } from "./components_dep/EditView";
import { KolProfile } from "./types/KolProfile";
import { CardWrapper } from "./components_dep/CardWrapper";
import { BackButton } from "./components_dep/Button";
import {
  FETCH_KOL_PROFILE,
  SAVE_KOL_FILES,
  SAVE_KOL_PROD_CATS,
  SAVE_KOL_PROFILE,
} from "./queries";
import IconBackBtn from "./icons/Back.png";
import { Controller, useForm } from "react-hook-form";
import { isInvalid } from "./utils/validation";
import { ValidationError } from "./components/Errors";
import { buildDate } from "./utils/datetime";
import { TextInput } from "./components/TextInput";
import { AppRadioGroup } from "./components/RadioInput";
import { DateOfBirth } from "./core/DateOfBirth";
import { AppDropdownSingle } from "./components/DropdownInput";
import { useCurrencies } from "./hooks/useCurrencies";
import { useCountries } from "./hooks/useCountries";
import { VerifyEmail } from "./core/VerifyEmail";
import { Navbar } from "./core/Navbar";
import { ENV } from "./configs/env";
import {
  AccountBlockerAvailable,
  AccountBlockerPending,
} from "./components/AccountBlock";

export function App() {
  const { isAuthenticated, loginWithRedirect, isLoading, error, user } =
    useAuth0();

  useEffect(() => {
    if (error) {
      console.error("Error:", error);
      return;
    }

    console.log("Effect started. isAuthenticated:", isAuthenticated);

    // If authentication is still being determined, exit early.
    if (isLoading) {
      console.log("Authentication is still loading...");
      return;
    }

    if (!isAuthenticated) {
      console.log("Not authenticated, redirecting...");
      loginWithRedirect()
        .then(() => {
          console.log("Redirected for login.");
        })
        .catch((error) => {
          console.log("Failed to redirect:", error);
        });
    }
  }, [isAuthenticated, isLoading]);

  if (
    user &&
    typeof user?.email_verified === "boolean" &&
    !user?.email_verified
  ) {
    return <VerifyEmail />;
  }

  return (
    <Wrapper>
      <GlobalStyles />

      {/* {!isAuthenticated && <LoginButton />} */}
      <Navbar />
      {/* {isAuthenticated && <LogoutButton />} */}
      {isAuthenticated && <AuthenticatedView />}
    </Wrapper>
  );
}

export function Wrapper({ children }: { children: React.ReactNode }) {
  const { isLoading, error } = useAuth0();
  if (isLoading) {
    return <div style={{ color: "#fff" }}>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  return <>{children}</>;
}

export function LoginButton() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    return null;
  }

  return <button onClick={() => loginWithRedirect()}>Log in</button>;
}

export function LogoutButton() {
  const { isAuthenticated, logout } = useAuth0();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <button
      onClick={() => {
        logout({
          logoutParams: {
            returnTo: ENV.REACT_APP_FRONTEND_URL,
          },
        });
      }}
    >
      Log out
    </button>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

enum KolProfileActivationState {
  NOT_YET_CREATED = "not-yet-created",
  NOT_YET_APPROVED = "not-yet-approved",
  NOT_YET_ACTIVE = "not-yet-active",
  ACTIVE = "active",
}

function getKolProfileActivationState(
  kolProfile: KolProfile | undefined
): KolProfileActivationState {
  if (!kolProfile) {
    return KolProfileActivationState.NOT_YET_CREATED;
  }

  if (!kolProfile.is_approved) {
    return KolProfileActivationState.NOT_YET_APPROVED;
  }

  if (!kolProfile.is_active) {
    return KolProfileActivationState.NOT_YET_ACTIVE;
  }

  return KolProfileActivationState.ACTIVE;
}

export function AuthenticatedView() {
  const hasuraUserId = useHasuraUserId();

  const { data, refetch } = useQuery<{ kol_profile: [KolProfile] }>(
    FETCH_KOL_PROFILE,
    {
      variables: {
        user_id: hasuraUserId,
      },
    }
  );

  const listenForEvent = async (event: "profileSaved") => {
    switch (event) {
      case "profileSaved": {
        try {
          await refetch();
          console.log("Refetched!");
        } catch (error) {
          console.error("Error refetching: ", error);
        }
      }
    }
  };

  const kolProfile = data?.kol_profile?.[0];

  const kolProfileActivationState = getKolProfileActivationState(kolProfile);

  const renderView = () => {
    console.info("kolProfileActivationState", kolProfileActivationState);

    switch (kolProfileActivationState) {
      case KolProfileActivationState.NOT_YET_CREATED:
        // Form to create profile
        return <Card eventTriggered={listenForEvent} />;
      case KolProfileActivationState.NOT_YET_APPROVED:
        return <AccountBlockerPending />;
      case KolProfileActivationState.NOT_YET_ACTIVE:
        return <AccountBlockerAvailable />;
      case KolProfileActivationState.ACTIVE:
        return <EditView kolProfile={kolProfile!} onEvent={listenForEvent} />;
    }
  };

  return <Container>{renderView()}</Container>;
}

const FormHeading = styled.h2`
  color: var(--gray-black, #000);
  font-family: CerebriSans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
`;

const FormWrapper = styled.form`
  & > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

type MainButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  disabled?: boolean;
};

const MainButton = styled.button<MainButtonProps>`
  display: flex;
  height: 56px;
  padding: 11.5px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 100px;
  background: var(--primary-primary-2, #7b73f4);
  color: white;
  border-color: transparent;
  font-family: CerebriSans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  opacity: ${(p) => (p.disabled ? 0.8 : 1)};
`;

enum StepNames {
  BASIC_INFO,
  KOLS_PROFILE,
  KOLS_PAYMENT,
  SOCIAL_ACCOUNT,
}

const StepsList = [
  {
    type: StepNames.BASIC_INFO,
    title: "Basic Information",
    position: 1,
  },
  {
    type: StepNames.KOLS_PROFILE,
    title: "KOLs Profile",
    position: 2,
  },
  {
    type: StepNames.KOLS_PAYMENT,
    title: "KOLs Payment",
    position: 3,
  },
];

type CardProps = {
  eventTriggered?: (event: "profileSaved") => void;
};

export function Card(props: CardProps) {
  /////////////////
  ///// QUERY /////
  /////////////////
  const { currencies, getCurrencyByCode } = useCurrencies();
  const { countries, getCountryByCode } = useCountries();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    trigger,
    watch,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      // Basic Info
      firstName: "",
      lastName: "",
      gender: GenderCode.NONE,
      dobYear: 0,
      dobMonth: 0,
      dobDay: 0,
      country: "",
      currency: "",
      // KOLs Profile
      productCategories: [],
      // KOLs Payment
      fullName: "",
      idCardNumber: "",
      bankName: "",
      bankSwiftCode: "",
      bankCountry: "",
      bankCity: "",
      bankState: "",
      bankAddress: "",
      bankAccNo: "",
      bankDetailsUpload1: "",
      bankDetailsUpload2: "",
      bankDetailsUpload3: "",
      bankDetailsUpload4: "",
      bankDetailsUpload5: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [saveKolProfile /*{ data1, loading1, error1 }*/] =
    useMutation(SAVE_KOL_PROFILE);
  const [saveKolProdCats /*{ data2, loading2, error2 }*/] =
    useMutation(SAVE_KOL_PROD_CATS);
  const [saveKolFiles /*{ data3, loading3, error3 }*/] =
    useMutation(SAVE_KOL_FILES);

  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const currentStep = StepsList[currentStepIdx];

  async function handleNextClick() {
    if (currentStepIdx === 0) {
      await trigger("gender");
      await trigger("firstName");
      await trigger("lastName");
      await trigger("dobYear");
      await trigger("dobMonth");
      await trigger("dobDay");
      await trigger("country");
      await trigger("currency");
    } else if (currentStepIdx === 1) {
      await trigger("productCategories");
    } else if (currentStepIdx === 2) {
      await trigger("fullName");
      await trigger("idCardNumber");
      await trigger("bankName");
      await trigger("bankSwiftCode");
      await trigger("bankCountry");
      await trigger("bankCity");
      await trigger("bankState");
      await trigger("bankAddress");
      await trigger("bankAccNo");
    }

    if (!isDirty) {
      console.error(`Card.handleNextClick`, `You haven't changed anything!`);
      return;
    }

    const TabIdxToValidationFields: Record<string, string[]> = {
      "0": [
        "firstName",
        "lastName",
        "gender",
        "dobYear",
        "dobMonth",
        "dobDay",
        "country",
        "currency",
      ],
      "1": ["productCategories"],
      "2": [
        "fullName",
        "idCardNumber",
        "bankName",
        "bankSwiftCode",
        "bankCountry",
        "bankCity",
        "bankState",
        "bankAddress",
        "bankAccNo",
      ],
    };

    if (
      isInvalid({
        errors,
        validationFields: TabIdxToValidationFields[String(currentStepIdx)],
      })
    ) {
      return;
    }

    if (currentStepIdx === StepsList.length - 1) {
      console.error(`Card.handleNextClick`, `You're at the last step!`);
      await saveKolProfileFn();
      return;
    }

    setCurrentStepIdx((prev) => prev + 1);
  }

  async function saveKolProfileFn() {
    const fullName = getValues("fullName");
    const idCardNumber = getValues("idCardNumber");
    const bankName = getValues("bankName");
    const bankSwiftCode = getValues("bankSwiftCode");
    const bankCountry = getValues("bankCountry");
    const bankCity = getValues("bankCity");
    const bankState = getValues("bankState");
    const bankAddress = getValues("bankAddress");
    const bankAccNo = getValues("bankAccNo");
    const firstName = getValues("firstName");
    const lastName = getValues("lastName");
    const dobYear = getValues("dobYear");
    const dobMonth = getValues("dobMonth");
    const dobDay = getValues("dobDay");
    const country = getValues("country");
    const currency = getValues("currency");
    const gender = getValues("gender");
    const productCategories = getValues("productCategories");

    console.info("Saving KOL profile...", {
      fullName,
      idCardNumber,
      bankName,
      bankSwiftCode,
      bankCountry,
      bankCity,
      bankState,
      bankAddress,
      bankAccNo,
      firstName,
      lastName,
      dobYear,
      dobMonth,
      dobDay,
      country,
      currency,
      gender,
      productCategories,
    });

    const result = await saveKolProfile({
      variables: {
        gender: gender,
        date_of_birth: buildDate({
          year: dobYear,
          month: dobMonth,
          day: dobDay,
        }),
        country_code: country,
        display_currency: currency,
        individual_fullname: fullName,
        individual_id_no: idCardNumber,
        bank_name: bankName,
        bank_country: bankCountry,
        bank_city: bankCity,
        bank_state: bankState,
        bank_address: bankAddress,
        bank_account_no: bankAccNo,
        first_name: firstName,
        last_name: lastName,
        bank_swift_code: bankSwiftCode,
      },
    });
    console.log("result", result);

    const kolId = result?.data?.insert_kol_profile_one?.id;

    if (!kolId) {
      console.error("No kolId found");
      return;
    }

    const prodCatInput = productCategories.map((interestCode) => ({
      kol_id: kolId,
      interest_code: interestCode,
    }));

    const result2 = await saveKolProdCats({
      variables: {
        objects: prodCatInput,
      },
    });
    console.log("result2", result2);

    const fileMetadataInput = [
      watch("bankDetailsUpload1"),
      watch("bankDetailsUpload2"),
      watch("bankDetailsUpload3"),
      watch("bankDetailsUpload4"),
      watch("bankDetailsUpload5"),
    ]
      .filter((x) => !!x)
      .map((x) => ({
        kol_profile_id: kolId,
        file_metadata_id: x,
        type: "bank_details",
      }));

    const saveKolFilesRes = await saveKolFiles({
      variables: {
        objects: fileMetadataInput,
      },
    });
    console.log("saveKolProfileFn.saveKolFiles", saveKolFilesRes);

    if (typeof props.eventTriggered === "function") {
      props.eventTriggered?.("profileSaved");
    }
  }

  function handleBackClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    if (currentStepIdx === 0) {
      console.error(`Card.handleNextClick`, `You're at the first step!`);
      return;
    }

    setCurrentStepIdx((prev) => prev - 1);
  }

  return (
    <CardWrapper>
      <BackButton onClick={handleBackClick}>
        <img src={IconBackBtn} alt="Back" />
      </BackButton>
      <FormHeading>{currentStep.title}</FormHeading>
      <Progress percentage={(currentStep.position * 100) / StepsList.length} />
      <FormWrapper>
        {/* ====== STEP 1 ====== */}
        {currentStep.type === StepNames.BASIC_INFO && (
          <>
            {/* <FirstnameTextInput
              register={register}
              error={errors.firstName?.message}
            /> */}
            <Controller
              name="firstName"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  id="firstName"
                  label="First name"
                  onChange={(x) => field.onChange(x)}
                  value={field.value}
                  error={error?.message}
                />
              )}
            />
            {/* <LastnameTextInput
              register={register}
              error={errors.lastName?.message}
            /> */}
            <Controller
              name="lastName"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  id="lastName"
                  label="Last name"
                  onChange={(x) => field.onChange(x)}
                  value={field.value}
                  error={error?.message}
                />
              )}
            />
            {/* <GenderPickerDeprecated
              gender={watch("gender")}
              register={register}
              error={errors.gender?.message}
            /> */}
            <Controller
              name="gender"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <AppRadioGroup
                  error={error?.message}
                  label="Gender"
                  value={field.value}
                  onChange={(x: any) => field.onChange(x)}
                  valuesList={[
                    {
                      id: "male",
                      value: GenderCode.MALE,
                      label: "Male",
                    },
                    {
                      id: "female",
                      value: GenderCode.FEMALE,
                      label: "Female",
                    },
                    {
                      id: "others",
                      value: GenderCode.OTHERS,
                      label: "Others",
                    },
                  ]}
                />
              )}
            />
            {/* <div>
              <DOBSelect
                errorYear={errors.dobYear?.message}
                errorMonth={errors.dobMonth?.message}
                errorDay={errors.dobDay?.message}
                register={register}
              />
              <DOBWarning>
                You must be at least 16 years old to use this platform
              </DOBWarning>
            </div> */}
            <DateOfBirth control={control} watch={watch} />
            {/* <CountrySelector
              country={watch("country")}
              register={register}
              error={errors.country?.message}
            /> */}
            <Controller
              name="country"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <AppDropdownSingle
                  id="country"
                  options={countries.map((x) => ({
                    value: x.code,
                    label: x.name,
                  }))}
                  placeholder="Country"
                  onChange={(x) => field.onChange(x)}
                  error={error?.message}
                  value={{
                    value: watch("country"),
                    label: getCountryByCode(watch("country"))?.name || "",
                  }}
                  label="Country"
                />
              )}
            />
            {/* <CurrencySelector
              currency={watch("currency")}
              register={register}
              error={errors.currency?.message}
            /> */}
            <Controller
              name="currency"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <AppDropdownSingle
                  id="currency"
                  options={currencies.map((x) => ({
                    value: x.code,
                    label: x.name,
                  }))}
                  onChange={(x) => field.onChange(x)}
                  error={error?.message}
                  value={{
                    value: watch("currency"),
                    label: getCurrencyByCode(watch("currency"))?.name || "",
                  }}
                  label="Currency display"
                />
              )}
            />
          </>
        )}

        {/* ====== STEP 2 ====== */}
        {currentStep.type === StepNames.KOLS_PROFILE && (
          <>
            <p>
              Select at least one category that best fits your influencer
              profile
            </p>
            <ProductCats
              productCategories={watch("productCategories")}
              register={register}
              error={errors.productCategories?.message}
            />
          </>
        )}

        {/* ====== STEP 3 ====== */}
        {currentStep.type === StepNames.KOLS_PAYMENT && (
          <>
            <h2>Personal Information</h2>
            <Controller
              name="fullName"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <Fragment>
                  <CustomTextInputDeprecated
                    textValue={field.value}
                    onInputChange={field.onChange}
                    label="Full name"
                    error={error?.message}
                  />
                </Fragment>
              )}
            />
            <Controller
              name="idCardNumber"
              control={control}
              rules={{
                required: "Field is required",
                pattern: {
                  message: `ID card number could not contain special characters( -, /, &, *, +)`,
                  value: /^[^-,/&*+]+$/,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomTextInputDeprecated
                  textValue={field.value}
                  onInputChange={field.onChange}
                  label="ID card number"
                  error={error?.message}
                />
              )}
            />
            <h2>Bank Details</h2>
            <Controller
              name="bankName"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <CustomTextInputDeprecated
                  textValue={field.value}
                  onInputChange={field.onChange}
                  label="Bank Name"
                  error={error?.message}
                />
              )}
            />

            <Controller
              name="bankSwiftCode"
              control={control}
              rules={{
                required:
                  watch("bankCountry") === "MY"
                    ? false
                    : "Enter the SWIFT code for the bank",
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomTextInputDeprecated
                  textValue={field.value}
                  onInputChange={field.onChange}
                  label="Bank SWIFT code"
                  error={error?.message}
                />
              )}
            />

            <h2>Bank Address</h2>
            {/* <Controller
              name="bankCountry"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <CountrySelectorNew
                  country={field.value}
                  onChangeCountry={field.onChange}
                  error={error?.message}
                />
              )}
            /> */}
            <Controller
              name="bankCountry"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <AppDropdownSingle
                  id="bankCountry"
                  options={countries.map((x) => ({
                    value: x.code,
                    label: x.name,
                  }))}
                  placeholder="Country"
                  onChange={(x) => field.onChange(x)}
                  error={error?.message}
                  value={{
                    value: watch("bankCountry"),
                    label: getCountryByCode(watch("bankCountry"))?.name || "",
                  }}
                  label="Country"
                />
              )}
            />
            <Controller
              name="bankCity"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <CustomTextInputDeprecated
                  textValue={field.value}
                  onInputChange={field.onChange}
                  label="City"
                  error={error?.message}
                />
              )}
            />
            <Controller
              name="bankState"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <CustomTextInputDeprecated
                  textValue={field.value}
                  onInputChange={field.onChange}
                  label="State"
                  error={error?.message}
                />
              )}
            />

            <Controller
              name="bankAddress"
              control={control}
              rules={{ required: "Field is required" }}
              render={({ field, fieldState: { error } }) => (
                <CustomTextInputDeprecated
                  textValue={field.value}
                  onInputChange={field.onChange}
                  label="Address"
                  error={error?.message}
                />
              )}
            />

            <Controller
              name="bankAccNo"
              control={control}
              rules={{
                required: "Field is required",
                pattern: {
                  message: `ID card number could not contain special characters( -, /, &, *, +)`,
                  value: /^[^-,/&*+]+$/,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomTextInputDeprecated
                  textValue={field.value}
                  onInputChange={field.onChange}
                  label="Bank Acc No"
                  error={error?.message}
                />
              )}
            />

            <h2>Bank Details Upload</h2>
            <UploadInputDeprecatedWrapper>
              <Controller
                name="bankDetailsUpload1"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <UploadInputDeprecated
                    fileMetadataId={field.value}
                    setFileMetadataId={(value) => field.onChange(value)}
                    name="bankDetailsUpload1"
                  />
                )}
              />
              <Controller
                name="bankDetailsUpload2"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <UploadInputDeprecated
                    fileMetadataId={field.value}
                    setFileMetadataId={(value) => field.onChange(value)}
                    name="bankDetailsUpload2"
                  />
                )}
              />
              <Controller
                name="bankDetailsUpload3"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <UploadInputDeprecated
                    fileMetadataId={field.value}
                    setFileMetadataId={(value) => field.onChange(value)}
                    name="bankDetailsUpload3"
                  />
                )}
              />
              <Controller
                name="bankDetailsUpload4"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <UploadInputDeprecated
                    fileMetadataId={field.value}
                    setFileMetadataId={(value) => field.onChange(value)}
                    name="bankDetailsUpload4"
                  />
                )}
              />
              <Controller
                name="bankDetailsUpload5"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <UploadInputDeprecated
                    fileMetadataId={field.value}
                    setFileMetadataId={(value) => field.onChange(value)}
                    name="bankDetailsUpload5"
                  />
                )}
              />
            </UploadInputDeprecatedWrapper>
            {!watch("bankDetailsUpload1") &&
              !watch("bankDetailsUpload2") &&
              !watch("bankDetailsUpload3") &&
              !watch("bankDetailsUpload4") &&
              !watch("bankDetailsUpload5") && (
                <ValidationError>At least 1 photo is required</ValidationError>
              )}
          </>
        )}

        <MainButton
          /*disabled={isNextDisabled}*/ onClick={handleNextClick}
          type="button"
        >
          Next
        </MainButton>
      </FormWrapper>
    </CardWrapper>
  );
}

const GlobalStyles = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }
`;
