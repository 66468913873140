import { useState } from "react";
import { styled } from "styled-components";
import CameraIcon from "../icons/Camera.png";
import { ENV } from "../configs/env";

export type AvatarUploadProps = {
  fileMetadataId: string;
  setFileMetadataId: (value: string) => void;
  url?: string;
  disabled?: boolean;
};

export const AvatarUpload = (props: AvatarUploadProps) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const uploadImage = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(`${ENV.REACT_APP_WEBHOOKS_BASE_URL}/files`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("File uploaded:", data);
        props.setFileMetadataId(data?.id);
      })
      .catch((error) => {
        console.error("Error uploading the file:", error);
      });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(reader.result as string);
    };

    reader.readAsDataURL(file);

    uploadImage(file);
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <img
          src={selectedImage || props.url || "https://placehold.co/400x400"}
          width={120}
          height={120}
          alt="avatar"
          style={{ borderRadius: "50%" }}
        />
        {!props.disabled && (
          <IconWrapper>
            <img
              src={CameraIcon}
              alt="camera"
              height={24}
              width={24}
              style={{ zIndex: 1, cursor: "pointer" }}
            />
            <FileInput
              id="avatarUpload"
              type="file"
              onChange={handleImageChange}
            />
            <FileInputLabel htmlFor="avatarUpload"></FileInputLabel>
          </IconWrapper>
        )}
      </InnerWrapper>
      {/* <ValidationError>Avatar is required</ValidationError> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const IconWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #7b73f4;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const InnerWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  z-index: 1;
`;
