import { useQuery } from "@apollo/client";
import { GET_METRICS } from "../queries";
import { KolMetric } from "../types/KolProfile";

export const useMetrics = () => {
  const { data } = useQuery<{
    kol_metrics: KolMetric[];
  }>(GET_METRICS);

  return {
    data: data?.kol_metrics?.[0],
  };
};
