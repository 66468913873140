export const ENV = {
  REACT_APP_APOLLO_GRAPHQL_URL: process.env.REACT_APP_APOLLO_GRAPHQL_URL || "",
  REACT_APP_AUTH0_HOST: process.env.REACT_APP_AUTH0_HOST || "",
  REACT_APP_AUTH0_KOL_CLIENT_ID:
    process.env.REACT_APP_AUTH0_KOL_CLIENT_ID || "",
  REACT_APP_WEBHOOKS_BASE_URL: process.env.REACT_APP_WEBHOOKS_BASE_URL || "",
  REACT_APP_FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL || "",
};

export const checkEnvVars = () => {
  // check if any env is undefined
  Object.keys(ENV).forEach((key) => {
    const val = (ENV as any)[key];
    if (!val) {
      throw new Error(`Env "${key}" is undefined`);
    }
  });
};
