import cat_fashion from "../icons/cat_fashion.png";
import cat_food from "../icons/cat_food.png";
import cat_music from "../icons/cat_music.png";
import cat_design from "../icons/cat_design.png";
import cat_beauty from "../icons/cat_beauty.png";
import cat_fitness from "../icons/cat_fitness.png";
import cat_gaming from "../icons/cat_gaming.png";
import cat_parenthood from "../icons/cat_parenthood.png";
import cat_travel from "../icons/cat_travel.png";
import cat_photography from "../icons/cat_photography.png";
import cat_gadgets from "../icons/cat_gadgets.png";

export const mapCategoryCodeToPhoto: Record<
  string,
  { code: string; name: string; photo: string }
> = {
  PCAT1: {
    code: "PCAT1",
    name: "Fashion",
    photo: cat_fashion,
  },
  PCAT2: {
    code: "PCAT2",
    name: "Food",
    photo: cat_food,
  },
  PCAT3: {
    code: "PCAT3",
    name: "Music",
    photo: cat_music,
  },
  PCAT4: {
    code: "PCAT4",
    name: "Design",
    photo: cat_design,
  },
  PCAT5: {
    code: "PCAT5",
    name: "Beauty",
    photo: cat_beauty,
  },
  PCAT6: {
    code: "PCAT6",
    name: "Fitness",
    photo: cat_fitness,
  },
  PCAT7: {
    code: "PCAT7",
    name: "Gaming",
    photo: cat_gaming,
  },
  PCAT8: {
    code: "PCAT8",
    name: "Parenthood",
    photo: cat_parenthood,
  },
  PCAT9: {
    code: "PCAT9",
    name: "Travel",
    photo: cat_travel,
  },
  PCAT10: {
    code: "PCAT10",
    name: "Photography",
    photo: cat_photography,
  },
  PCAT11: {
    code: "PCAT11",
    name: "Gadgets",
    photo: cat_gadgets,
  },
};
