import styled from "styled-components";
import { ValidationError } from "./Errors";
import { AppLabel, CheckLabel } from "./Label";

const RadioItem = styled.input`
  display: none;
`;

const RadioItemWrapper = styled.div``;

type AppRadioGroupProps = {
  label: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  valuesList: {
    id: string;
    value: string;
    label: string;
  }[];
  disabled?: boolean;
};

export const AppRadioGroup = function (props: AppRadioGroupProps) {
  return (
    <Wrapper>
      <LineWrapper>
        <AppLabel>{props.label}</AppLabel>
        <RadioItemsListWrapper>
          {props.valuesList.map((x) => (
            <RadioItemWrapper key={x.value}>
              <CheckLabel
                selected={props.value === x.value}
                htmlFor={x.id}
                onClick={() => {
                  if (props.disabled) return;
                  props.onChange(x.value);
                }}
              >
                {x.label}
              </CheckLabel>
              <RadioItem
                disabled={props.disabled}
                key={x.id}
                type="radio"
                id={x.id}
                value={x.value}
              />
            </RadioItemWrapper>
          ))}
        </RadioItemsListWrapper>
      </LineWrapper>
      {props.error && <ValidationError>{props.error || ""}</ValidationError>}
    </Wrapper>
  );
};

const RadioItemsListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-left: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const LineWrapper = styled.div`
  display: flex;
  align-items: center;
`;
