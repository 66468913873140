import { styled } from "styled-components";
import { AppDropdownSingle } from "../components/DropdownInput";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { dateAppProps, isValidDate } from "../utils/datetime";
import { addYears, isBefore } from "date-fns";

const monthMapping: Record<string, string> = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "July",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
const days = Array.from({ length: 31 }, (_, i) => i + 1);
const months = Array.from({ length: 12 }, (_, i) => i + 1);
const years = Array.from(
  { length: 122 },
  (_, i) => new Date().getFullYear() - i
);

const monthOptions = months.map((x) => ({
  value: x.toString(),
  label: monthMapping[x.toString()],
}));
const dayOptions = days.map((x) => ({
  value: x.toString(),
  label: x.toString(),
}));
const yearOptions = years.map((x) => ({
  value: x.toString(),
  label: x.toString(),
}));

type DateOfBirthProps = {
  control: Control<any>;
  watch: UseFormWatch<any>;
  disabled?: boolean;
};

export const DateOfBirth = (props: DateOfBirthProps) => {
  return (
    <div>
      <CalendarWrapper>
        <Controller
          name="dobDay"
          control={props.control}
          rules={{
            required: "Field is required",
            validate: (x, y) =>
              validateAge16({
                day: x,
                month: props.watch("dobMonth"),
                year: props.watch("dobYear"),
              }),
          }}
          render={({ field, fieldState: { error } }) => (
            <AppDropdownSingle
              id="dobDay"
              options={dayOptions}
              placeholder="Day"
              onChange={(x) => field.onChange(x)}
              error={error?.message}
              value={
                props.watch("dobDay") === 0
                  ? null
                  : {
                      value: props.watch("dobDay"),
                      label: props.watch("dobDay"),
                    }
              }
              disabled={props.disabled}
            />
          )}
        />

        <Controller
          name="dobMonth"
          control={props.control}
          rules={{
            required: "Field is required",
            validate: (x, y) =>
              validateAge16({
                day: props.watch("dobDay"),
                month: x,
                year: props.watch("dobYear"),
              }),
          }}
          render={({ field, fieldState: { error } }) => (
            <AppDropdownSingle
              id="dobMonth"
              options={monthOptions}
              placeholder="Month"
              onChange={(x) => field.onChange(x)}
              error={error?.message}
              value={
                props.watch("dobMonth") === 0
                  ? null
                  : {
                      value: props.watch("dobMonth"),
                      label: monthMapping[props.watch("dobMonth")],
                    }
              }
              disabled={props.disabled}
            />
          )}
        />

        <Controller
          name="dobYear"
          control={props.control}
          rules={{
            required: "Field is required",
            validate: (x, y) => {
              return validateAge16({
                day: props.watch("dobDay"),
                month: props.watch("dobMonth"),
                year: x,
              });
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <AppDropdownSingle
              id="dobYear"
              options={yearOptions}
              placeholder="Year"
              onChange={(x) => field.onChange(x)}
              error={error?.message}
              value={
                props.watch("dobYear") === 0
                  ? null
                  : {
                      value: props.watch("dobYear"),
                      label: props.watch("dobYear"),
                    }
              }
              disabled={props.disabled}
            />
          )}
        />
      </CalendarWrapper>
      <Warning
        style={
          {
            "--color": validateAge16({
              day: props.watch("dobDay"),
              month: props.watch("dobMonth"),
              year: props.watch("dobYear"),
            })
              ? "inherit"
              : "red",
          } as any
        }
      >
        You must be at least 16 years old to use this platform
      </Warning>
    </div>
  );
};

// Assume only consider validating when date is valid
// Incase of invalid date, don't care and return true cause it will be handled by other validation
function validateAge16(date: dateAppProps) {
  const dateOrNull = isValidDate({
    day: date.day,
    month: date.month,
    year: date.year,
  });

  if (!dateOrNull) {
    return true;
  }

  const validDate = addYears(dateOrNull, 16);

  return isBefore(validDate, new Date());
}

const CalendarWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 20px;

  & > * {
    flex: 1;
  }
`;

const Warning = styled.p`
  color: var(--color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
