import styled from "styled-components";
import FilePlusIcon from "../icons/file_plus.png";
import FileCloseIcon from "../icons/file_close.png";
import React, { Fragment, useEffect, useState } from "react";
import { ENV } from "../configs/env";
import ImgsViewer from "react-images-viewer";

enum ItemState {
  Hidden = "hidden",
  VisibleImageNotPersisted = "visibleImageNotPersisted",
  VisibleImagePersisted = "visibleImagePersisted",
  VisiblePlus = "visiblePlus",
}

export type UploadInputProps = {
  fileMetadataId: string;
  setFileMetadataId: (
    value: string,
    event?: "add" | "remove",
    index?: number,
    url?: string
  ) => void;
  url?: string;
  name?: string;
  index?: number;
  state: ItemState;
  disabled?: boolean;
};

export const UploadInput = (props: UploadInputProps) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [internalFileMetadataId, setInternalFileMetadataId] = useState("");
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [doneUploaded, setDoneUploaded] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (props.fileMetadataId) {
      setDoneUploaded(true);
    } else {
      setDoneUploaded(false);
    }
  }, [props.fileMetadataId]);

  useEffect(() => {
    if (!!internalFileMetadataId && !!selectedImage && !doneUploaded) {
      props.setFileMetadataId(
        internalFileMetadataId,
        "add",
        props.index,
        selectedImage as string
      );
      setDoneUploaded(true);
    }
  }, [
    internalFileMetadataId,
    selectedImage,
    doneUploaded,
    props.setFileMetadataId,
    props,
  ]);

  const uploadImage = (file: File) => {
    if (props.disabled) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    fetch(`${ENV.REACT_APP_WEBHOOKS_BASE_URL}/files`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("uploadImage.networkError");
        }
        return response.json();
      })
      .then((data) => {
        console.log("uploadImage.uploaded:", data);
        setInternalFileMetadataId(data?.id);
      })
      .catch((error) => {
        console.error("uploadImage.error:", error);
      });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) {
      return;
    }

    const file = e.target.files?.[0];
    console.log("handleImageChange.file:", file);

    if (!file) {
      console.error("handleImageChange.noFile");
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(reader.result as string);
    };

    reader.readAsDataURL(file);

    uploadImage(file);
  };

  const handleRemoveImage = async () => {
    if (props.disabled) {
      return;
    }

    setInternalFileMetadataId("");
    setSelectedImage(null);
    setDoneUploaded(false);

    if (props.fileMetadataId) {
      props.setFileMetadataId(props.fileMetadataId, "remove", props.index);
    }
  };

  if (props.state === ItemState.Hidden) {
    return null;
  }

  return (
    <InternalUploadInputWrapper>
      {props.state === ItemState.VisiblePlus && (
        <Fragment>
          <UploadInputPlusIconWrapper>
            <img src={FilePlusIcon} alt="plus icon" />
          </UploadInputPlusIconWrapper>
          <UploadInputCore
            ref={fileInputRef}
            type="file"
            onChange={handleImageChange}
          />
        </Fragment>
      )}

      {[
        ItemState.VisibleImagePersisted,
        ItemState.VisibleImageNotPersisted,
      ].includes(props.state) && (
        <Fragment>
          <UploadInputCore
            ref={fileInputRef}
            type="file"
            onChange={handleImageChange}
            disabled={props.disabled}
          />
          {!props.disabled && (
            <UploadInputCloseIconWrapper>
              <img
                src={FileCloseIcon}
                alt="close icon"
                onClick={handleRemoveImage}
              />
            </UploadInputCloseIconWrapper>
          )}

          <img
            src={props.url}
            alt="Selected"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            onClick={() => {
              console.log("CLICKED IMG");
              setExpanded(true);
            }}
          />
          <ImgsViewer
            imgs={[{ src: props.url, srcSet: [] }]}
            isOpen={expanded}
            // onClickImg={() => {console.log("CLICKED IMG");}}
            // onClickPrev={this.gotoPrevious}
            // onClickNext={this.gotoNext}
            onClose={() => {
              setExpanded(false);
            }}
          />
        </Fragment>
      )}
    </InternalUploadInputWrapper>
  );
};

const InternalUploadInputWrapper = styled.span`
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px dashed var(--gray-gray-400, #949494);
  position: relative;

  &:hover {
    border: 3px dashed var(--gray-gray-400, #949494);
    cursor: pointer;
  }
`;

const UploadInputPlusIconWrapper = styled.span<{
  isHidden?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  ${(p) => p.isHidden && "display: none;"}
`;

const UploadInputCloseIconWrapper = styled.span<{
  isHidden?: boolean;
}>`
  ${(p) => p.isHidden && "display: none;"}
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: var(--bg-darkmode, #181a1c);
  opacity: 0.800000011920929;
  mix-blend-mode: multiply;
  border-radius: 50%;
  padding: 4px;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  ${InternalUploadInputWrapper}:hover & {
    top: -10px;
    right: -10px;
  }
`;

const UploadInputCore = styled.input<{ disabled?: boolean }>`
  ${(p) => p.disabled && "display: none;"}
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;

  &:hover {
    cursor: pointer;
  }
`;

export const UploadInputDeprecatedWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  margin: 16px 0;
`;
