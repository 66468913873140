import { gql } from "@apollo/client";

export const GET_PRODUCT_CATS = gql`
  query GetProductCats {
    product_category {
      code
      name
      is_active
    }
  }
`;

export const GET_METRICS = gql`
  query GetMetrics {
    kol_metrics {
      id
      user_id
      kol_profile_id
      total_engagement
      total_followers_count
      updated_at
      created_at
      kol_audience_demographic_data {
        id
        category
        created_at
        data
        updated_at
        kol_metric_id
      }
      kol_metrics_by_platforms {
        engagement
        followers_count
        created_at
        id
        impression_rate
        platform
        reach_rate
        updated_at
        kol_metric_id
      }
    }
  }
`;

export const SAVE_KOL_PROD_CATS = gql`
  mutation saveKolProdCats(
    $objects: [kol_profile_product_categories_insert_input!]!
  ) {
    insert_kol_profile_product_categories(objects: $objects) {
      returning {
        interest_code
        kol_id
      }
    }
  }
`;

export const DELETE_KOL_PROD_CATEGORIES = gql`
  mutation deleteKolProdCats($uuids: [String!]!) {
    delete_kol_profile_product_categories(
      where: { interest_code: { _in: $uuids } }
    ) {
      returning {
        interest_code
      }
      affected_rows
    }
  }
`;
