import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import { CategoryButton } from "./CategoryButton";
import { UseFormRegister } from "react-hook-form";
import { Fragment } from "react";
import { ValidationErrorDeprecated } from "./ValidationError";
import { mapCategoryCodeToPhoto } from "../utils/photos";

const CategoryButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
`;

const GET_PRODUCT_CATS = gql`
  query GetCurrencyCats {
    product_category {
      code
      name
      is_active
    }
  }
`;

export type ProductCatsDeprecatedProps = {
  setProductCategories: React.Dispatch<React.SetStateAction<Set<string>>>;
  productCategories: Set<string>;
};

export const ProductCatsDeprecated = (props: ProductCatsDeprecatedProps) => {
  const { data } = useQuery<{
    product_category: {
      code: string;
      name: string;
      is_active: number;
    }[];
  }>(GET_PRODUCT_CATS);

  const onBtnClick = (e: any) => {
    e.preventDefault();
    props.setProductCategories((prev) => {
      // Make a copy of the previous state so we can safely mutate it
      const newSet = new Set(prev);

      if (newSet.has(e.target.value)) {
        newSet.delete(e.target.value);
      } else {
        newSet.add(e.target.value);
      }

      // Return the new set, which is a different reference than the original 'prev'
      // Since the reference is different, React will recognize it as a change and re-render the component as expected.
      return newSet;
    });
  };

  return (
    <CategoryButtonWrapper>
      {data?.product_category?.map((x) => (
        <CategoryButton
          selected={props.productCategories.has(x.code)}
          key={x.code}
          value={x.code}
          onClick={onBtnClick}
        >
          {x.name}
        </CategoryButton>
      ))}
    </CategoryButtonWrapper>
  );
};

export type ProductCatsProps = {
  productCategories: string[];
  register: UseFormRegister<any>;
  error?: string;
};

export const ProductCats = (props: ProductCatsProps) => {
  const { data } = useQuery<{
    product_category: {
      code: string;
      name: string;
      is_active: number;
    }[];
  }>(GET_PRODUCT_CATS);

  console.log("data", data?.product_category);

  return (
    <Fragment>
      <CategoryButtonWrapper>
        {data?.product_category?.map((x) => (
          <CategoryLabel
            key={x.code}
            htmlFor={x.code}
            selected={props.productCategories.includes(x.code)}
          >
            {mapCategoryCodeToPhoto[x.code] && (
              <img
                src={mapCategoryCodeToPhoto[x.code].photo}
                alt={mapCategoryCodeToPhoto[x.code].name}
                height={20}
                width={20}
                style={{ marginRight: 8 }}
              />
            )}
            <CategoryInput
              id={x.code}
              type="checkbox"
              value={x.code}
              {...props.register("productCategories", {
                required: "Select at least one",
              })}
            />
            {x.name}
          </CategoryLabel>
        ))}
      </CategoryButtonWrapper>
      {props.error && (
        <ValidationErrorDeprecated>
          {props.error || ""}
        </ValidationErrorDeprecated>
      )}
    </Fragment>
  );
};

const CategoryLabel = styled.label<{ selected: boolean }>`
  border-radius: 100px;
  border: ${(p) => (p.selected ? "1px solid #7B73F4" : "1px solid #949494")};
  background: ${(p) => (p.selected ? "#DFDCFF" : "inherit")};
  padding: 10px;
  cursor: pointer;
  display: flex;
`;

const CategoryInput = styled.input`
  display: none;
`;
