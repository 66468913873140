import styled from "styled-components";

const ProgressBar = styled.div`
  width: 100%;
  height: 16px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  overflow: hidden;
`;

interface CurrentProgressProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  percentage: number;
}

const CurrentProgress = styled.div<CurrentProgressProps>`
  width: ${(p) => p.percentage}%;
  height: 100%;
  background-color: #7b73f4;
`;

export function Progress({ percentage = 0 }) {
  return (
    <ProgressBar>
      <CurrentProgress percentage={percentage} />
    </ProgressBar>
  );
}
