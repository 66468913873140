import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { ApolloProviderComposer } from "./providers/Apollo";
import { ENV, checkEnvVars } from "./configs/env";

import "./index.css";
import "@radix-ui/themes/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

checkEnvVars();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={ENV.REACT_APP_AUTH0_HOST}
      clientId={ENV.REACT_APP_AUTH0_KOL_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        // redirect_uri: window.location.origin + "/auth0/callback",
        // redirect_uri: window.location.origin,
        // redirect_uri: window.location.origin,
        // audience: `https://${ENV.REACT_APP_AUTH0_HOST}/api/v2/`,
        // scope: "read:current_user",
      }}
    >
      <ApolloProviderComposer>
        <div
          style={{
            background: "linear-gradient(to bottom right, #483285, white)",
            minHeight: "100vh",
            paddingBottom: "2rem",
            paddingTop: 1 /* fix margin collapsing, TODO: find better way */,
          }}
        >
          <App />
          <ToastContainer />
        </div>
      </ApolloProviderComposer>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
