import { styled } from "styled-components";
import { KolMetric, SocialPlatform } from "../types/KolProfile";
import IconInstagram from "../icons/social_instagram.png";

type SocialInfoProps = {
  socialInfo: SocialPlatform;
  metricInfo?: KolMetric;
};

const SocialInfoHeader = styled.div`
  border-radius: 8px;
  border: 1px solid #fff;
  background: linear-gradient(
    88deg,
    #feda75 -2.27%,
    #fa7e1e 22.16%,
    #d62976 55.46%,
    #962fbf 79.46%,
    #4f5bd5 104.31%
  );
  text-align: center;
  color: white;
  position: relative;
  padding: 20px 16px;
  font-weight: 700;
`;

const IconWrapper = styled.figure`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  border-radius: 100px;
  background: #fff;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialInfoContent = styled.div`
  border-radius: 8px;
  background: rgba(238, 238, 238, 0.56);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SocialInfoLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SocialInfoFigure = styled.span`
  font-weight: 700;
`;

export const SocialInfo = (props: SocialInfoProps) => {
  const metricByPlatform = props.metricInfo?.kol_metrics_by_platforms?.find(
    (x) =>
      x.platform?.toLowerCase() ===
      props.socialInfo?.social_platform_code?.toLowerCase()
  );

  console.log("metricByPlatform:", metricByPlatform);

  return (
    <div>
      <SocialInfoHeader>
        <IconWrapper>
          <img src={IconInstagram} alt="Instagram Icon" />
        </IconWrapper>
        {props.socialInfo.platform_username}
      </SocialInfoHeader>
      <SocialInfoContent>
        <SocialInfoLine>
          <span>Followers</span>
          <SocialInfoFigure>
            {props.metricInfo?.total_followers_count || "-"}
          </SocialInfoFigure>
        </SocialInfoLine>
        <SocialInfoLine>
          <span>Avg. Likes</span>
          <SocialInfoFigure>{`-`}</SocialInfoFigure>
        </SocialInfoLine>
        <SocialInfoLine>
          <span>Avg. Views</span>
          <SocialInfoFigure>{`-`}</SocialInfoFigure>
        </SocialInfoLine>
        <SocialInfoLine>
          <span>Engagement</span>
          <SocialInfoFigure>
            {metricByPlatform?.engagement || `-`}
          </SocialInfoFigure>
        </SocialInfoLine>
      </SocialInfoContent>
    </div>
  );
};
