import { styled } from "styled-components";

export const AppLabel = styled.label`
  color: #949494;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CheckLabel = styled.label<{ selected?: boolean }>`
  color: ${(p) => (p.selected ? "#7B73F4" : "inherit")};
  font-family: CerebriSans;
  font-size: 18px;
  font-style: normal;
  font-weight: ${(p) => (p.selected ? "700" : "400")};
  padding: 10px;
  border-radius: 100px;
  border: ${(p) => (p.selected ? "1px solid #7b73f4" : "1px solid #949494")};
  background: ${(p) => p.selected && "#DFDCFF"};
  display: block;
  cursor: pointer;
  display: flex;
`;
