import { styled } from "styled-components";
import { ValidationError } from "./Errors";
import { AppLabel } from "./Label";

const InternalNumericInput = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border: none;
  border-bottom: 0.5px solid #949494;
  outline: none;
  font-family: CerebriSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: right;
  padding: 16px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const TextInputLabel = styled(AppLabel)`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--gray-gray-400, #949494);
`;

type NumericInputProps = {
  label: string;
  onChange: (x: string) => void;
  value: string;
  id: string;
  error?: string;
  disabled?: boolean;
};

export const NumericInput = (
  props: NumericInputProps = {
    label: "",
    onChange: (x: string) => {},
    value: "",
    id: "",
    error: "",
    disabled: false,
  }
) => {
  return (
    <Wrapper>
      <TextInputLabel htmlFor={props.id}>{props.label}</TextInputLabel>
      <InternalNumericInput
        id={props.id}
        type="number"
        onChange={(e: any) => props.onChange(e.target.value)}
        value={props.value === null ? undefined : props.value}
        disabled={props.disabled}
        min={0}
        style={{ "--width": "100%" } as any}
      />
      {props.error && <ValidationError>{props.error}</ValidationError>}
    </Wrapper>
  );
};

const Symbol = styled.div`
  line-height: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 50px;
`;

const CurrencyInputWrapper = styled.div``;

type CurrencyInputProps = {
  label: string;
  onChange: (x: string) => void;
  value: string;
  id: string;
  error?: string;
  disabled?: boolean;
  symbol?: string;
};

const InternalCurrencyInput = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border: none;
  outline: none;
  font-family: CerebriSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: right;
  padding: 16px 0;
  width: 100%;
`;

const InternalCurrencyInputWrapper = styled.span`
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #949494;
`;

export const CurrencyInput = (
  props: CurrencyInputProps = {
    label: "",
    onChange: (x: string) => {},
    value: "",
    id: "",
    error: "",
    disabled: false,
    symbol: "",
  }
) => {
  return (
    <CurrencyInputWrapper>
      <AppLabel htmlFor={props.id}>{props.label}</AppLabel>
      <InternalCurrencyInputWrapper>
        {props.symbol && <Symbol>{props.symbol}</Symbol>}
        <InternalCurrencyInput
          id={props.id}
          type="number"
          onChange={(e: any) => props.onChange(e.target.value)}
          value={props.value === null ? undefined : props.value}
          disabled={props.disabled}
          min={0}
        />
      </InternalCurrencyInputWrapper>
      {props.error && <ValidationError>{props.error}</ValidationError>}
    </CurrencyInputWrapper>
  );
};
