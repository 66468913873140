import { FieldValues, UseFormRegister } from "react-hook-form";
import styled from "styled-components";
import { ValidationErrorDeprecated } from "./ValidationError";

const TextInput = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border: none;
  border-bottom: 0.5px solid #949494;
  outline: none;
  font-family: CerebriSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: right;
  padding: 16px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--gray-gray-400, #949494);
`;

type FirstnameTextInputProps = {
  register: UseFormRegister<any>;
  error?: string;
};

export const FirstnameTextInput = (props: FirstnameTextInputProps) => {
  return (
    <Wrapper>
      <Label>First name</Label>
      <TextInput
        type="text"
        placeholder="First Name"
        {...props.register("firstName", { required: "Field is required" })}
      />
      {props.error && (
        <ValidationErrorDeprecated>
          {props.error || ""}
        </ValidationErrorDeprecated>
      )}
    </Wrapper>
  );
};

type LastnameTextInputProps = {
  register: UseFormRegister<any>;
  error?: string;
};

export const LastnameTextInput = (props: LastnameTextInputProps) => {
  return (
    <Wrapper>
      <Label>Last name</Label>
      <TextInput
        type="text"
        placeholder="Last Name"
        {...props.register("lastName", { required: "Field is required" })}
      />
      {props.error && (
        <ValidationErrorDeprecated>
          {props.error || ""}
        </ValidationErrorDeprecated>
      )}
    </Wrapper>
  );
};

type CustomTextInputDeprecatedProps = {
  label: string;
  onInputChange: (x: string) => void;
  textValue: string;
  error?: string;
};

export const CustomTextInputDeprecated = (
  props: CustomTextInputDeprecatedProps
) => {
  return (
    <Wrapper>
      <Label>{props.label}</Label>
      <TextInput
        onChange={(e: any) => props.onInputChange(e.target.value)}
        type="text"
        value={props.textValue}
      />
      {props.error && (
        <ValidationErrorDeprecated>{props.error}</ValidationErrorDeprecated>
      )}
    </Wrapper>
  );
};
