import { styled } from "styled-components";
import { ValidationError } from "./Errors";
import { AppLabel } from "./Label";
import { RegularInfo } from "./Info";

const InternalTextInput = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border: none;
  border-bottom: 0.5px solid #949494;
  outline: none;
  font-family: CerebriSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: right;
  padding: 16px;
`;

export const InternalTextInputWrapper = styled.div`
  position: relative;

  & ${RegularInfo} {
    margin-top: 4px;
  }
`;

const TextInputLabel = styled(AppLabel)`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--gray-gray-400, #949494);
`;

export const TextInputWrapper = styled.div``;

type TextInputProps = {
  label: string;
  onChange: (x: string) => void;
  value: string;
  id: string;
  error?: string;
  info?: string;
  disabled?: boolean;
};

export const TextInput = (
  props: TextInputProps = {
    label: "",
    onChange: (x: string) => {},
    value: "",
    id: "",
    error: "",
    disabled: false,
  }
) => {
  return (
    <TextInputWrapper>
      <InternalTextInputWrapper>
        <TextInputLabel htmlFor={props.id}>{props.label}</TextInputLabel>
        <InternalTextInput
          id={props.id}
          type="text"
          onChange={(e: any) => props.onChange(e.target.value)}
          value={
            props.value === null
              ? undefined
              : props.value /* avoid warning when value is null */
          }
          disabled={props.disabled}
        />
      </InternalTextInputWrapper>
      {props.info && (
        <RegularInfo style={{ marginTop: 8 }}>{props.info}</RegularInfo>
      )}
      {props.error && <ValidationError>{props.error}</ValidationError>}
    </TextInputWrapper>
  );
};

type LabelTopTextInputProps = {
  label: string;
  onChange: (x: string) => void;
  value: string;
  id: string;
  error?: string;
  info?: string;
  disabled?: boolean;
};

const LabelTopTextInputLabel = styled(AppLabel)`
  display: flex;
  align-items: center;
  color: var(--gray-gray-400, #949494);
`;

const LabelTopInternalTextInput = styled.input`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border: none;
  border-bottom: 0.5px solid #949494;
  outline: none;
  font-family: CerebriSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: left;
  padding: 16px 0;
`;

export const LabelTopTextInput = (
  props: LabelTopTextInputProps = {
    label: "",
    onChange: (x: string) => {},
    value: "",
    id: "",
    error: "",
    disabled: false,
  }
) => {
  return (
    <TextInputWrapper>
      <LabelTopTextInputLabel htmlFor={props.id}>
        {props.label}
      </LabelTopTextInputLabel>
      <InternalTextInputWrapper>
        <LabelTopInternalTextInput
          id={props.id}
          type="text"
          onChange={(e: any) => props.onChange(e.target.value)}
          value={
            props.value === null
              ? undefined
              : props.value /* avoid warning when value is null */
          }
          disabled={props.disabled}
        />
      </InternalTextInputWrapper>
      {props.info && (
        <RegularInfo style={{ marginTop: 8 }}>{props.info}</RegularInfo>
      )}
      {props.error && <ValidationError>{props.error}</ValidationError>}
    </TextInputWrapper>
  );
};
