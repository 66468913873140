import { FieldErrors } from "react-hook-form";

export const isInvalid = (props: {
  validationFields: string[];
  errors: FieldErrors<any>;
}) => {
  return props.validationFields.some((field) =>
    Object.keys(props.errors).includes(field)
  );
};
