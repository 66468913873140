import styled from "styled-components";

interface CategoryButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  selected?: boolean;
}
export const CategoryButton = styled.button<CategoryButtonProps>`
  padding: 10px;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--gray-gray-400, #949494);
  border: ${(p) =>
    !!p.selected && "1px solid var(--primary-primary-2, #7B73F4)"};
  background: inherit;
  background: ${(p) => !!p.selected && "#DFDCFF"};
  color: ${(p) => !!p.selected && "var(--primary-primary-2, #7B73F4)"};
  font-weight: ${(p) => !!p.selected && 700};
  cursor: pointer;
`;
