import styled from "styled-components";
import { AppLabel, CheckLabel } from "./Label";
import { Control, Controller, useFormContext } from "react-hook-form";
import { ValidationError } from "./Errors";
import { mapCategoryCodeToPhoto } from "../utils/photos";

export type AppCheckboxProps = {
  options: { code: string; name: string }[];
  name: string;
  control: Control<any>;
  selectedOptions: string[];
  label?: string;
  disabled?: boolean;
};

const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const AppCheckboxInput = styled.input`
  display: none;
`;

const buildIdForAppCheckbox = (name: string, id: string) =>
  `AppCheckbox-Id-${name}-${id}`;

export const AppCheckbox = (props: AppCheckboxProps) => {
  // useFormContext expects a FormProvider to be present in the tree above it.
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const handleChange = (code: string) => {
    const currentValue = getValues(props.name) || [];
    if (currentValue.includes(code)) {
      setValue(
        props.name,
        currentValue.filter((item: string) => item !== code),
        { shouldDirty: true }
      );
    } else {
      setValue(props.name, [...currentValue, code], { shouldDirty: true });
    }
  };
  return (
    <Wrapper>
      <AppLabel>{props.label}</AppLabel>
      <CheckboxWrapper>
        {props.options.map((x) => (
          <CheckLabel
            htmlFor={buildIdForAppCheckbox(props.name, x.code)}
            key={x.code}
            selected={props.selectedOptions.includes(x.code)}
          >
            <Controller
              control={props.control}
              name={props.name}
              rules={{ required: "Select at least one" }}
              render={({ field }) => (
                <AppCheckboxInput
                  type="checkbox"
                  id={buildIdForAppCheckbox(props.name, x.code)}
                  value={x.code}
                  name={props.name}
                  // onChange={(e: any) => field.onChange(x.code)}
                  onChange={() => handleChange(x.code)}
                  checked={props.selectedOptions.includes(x.code)}
                  disabled={props.disabled}
                />
              )}
            />
            {mapCategoryCodeToPhoto[x.code] && (
              <img
                src={mapCategoryCodeToPhoto[x.code].photo}
                alt={mapCategoryCodeToPhoto[x.code].name}
                height={20}
                width={20}
                style={{ marginRight: 8 }}
              />
            )}
            {x.name}
          </CheckLabel>
        ))}
      </CheckboxWrapper>
      {errors?.[props.name] && (
        <ValidationError>
          {(errors as any)?.[props.name]?.message || ""}
        </ValidationError>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;
