import { UseFormRegister } from "react-hook-form";
import styled from "styled-components";
import { ValidationErrorDeprecated } from "./ValidationError";

const GenderPickerLabel = styled.span`
  color: var(--gray-gray-400, #949494);
  font-family: CerebriSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 1rem;
`;

const Radio = styled.input`
  display: none;
`;

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  selected?: boolean;
}

const Label = styled.label<LabelProps>`
  color: ${(p) => (p.selected ? "var(--gray-white, #fff)" : "inherit")};
  font-family: CerebriSans;
  font-size: 18px;
  font-style: normal;
  font-weight: ${(p) => (p.selected ? 700 : 400)}
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  border: ${(p) => (p.selected ? "1px solid #7b73f4" : "1px solid #949494")};
  background: ${(p) => p.selected && "var(--primary-primary-2, #7b73f4)"};
`;

const RadioItemWrapper = styled.span`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

type GenderPickerDeprecatedProps = {
  gender: GenderCode;
  register: UseFormRegister<any>;
  error?: string;
};

export const GenderPickerDeprecated = function (
  props: GenderPickerDeprecatedProps
) {
  return (
    <div>
      <GenderPickerLabel>Gender</GenderPickerLabel>
      {gendersList.map((x) => (
        <RadioItemWrapper key={x.id}>
          <Radio
            type="radio"
            id={x.id}
            value={x.value}
            {...props.register("gender", { required: "Field is required" })}
          />
          <Label selected={props.gender === x.value} htmlFor={x.id}>
            {x.label}
          </Label>
        </RadioItemWrapper>
      ))}
      {props.error && (
        <ValidationErrorDeprecated>
          {props.error || ""}
        </ValidationErrorDeprecated>
      )}
    </div>
  );
};

type GenderPickerProps = {
  setGender: React.Dispatch<React.SetStateAction<GenderCode>>;
  gender: GenderCode;
};

export const GenderPicker = function (props: GenderPickerProps) {
  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setGender(e.currentTarget.value as GenderCode);
  };

  return (
    <div>
      <GenderPickerLabel>Gender</GenderPickerLabel>
      {gendersList.map((x) => (
        <RadioItemWrapper key={x.id}>
          <Radio
            onChange={handleSelect}
            type="radio"
            id={x.id}
            name={x.name}
            value={x.value}
          />
          <Label selected={props.gender === x.value} htmlFor={x.id}>
            {x.label}
          </Label>
        </RadioItemWrapper>
      ))}
    </div>
  );
};

export enum GenderCode {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHERS = "OTHERS",
  NONE = "",
}

const gendersList: {
  id: string;
  name: string;
  value: GenderCode;
  label: string;
}[] = [
  {
    id: "male",
    name: "gender",
    value: GenderCode.MALE,
    label: "Male",
  },
  {
    id: "female",
    name: "gender",
    value: GenderCode.FEMALE,
    label: "Female",
  },
  {
    id: "others",
    name: "gender",
    value: GenderCode.OTHERS,
    label: "Others",
  },
];
