import Select from "react-select";
import { ValidationError } from "./Errors";
import makeAnimated from "react-select/animated";
import { AppLabel } from "./Label";
import { styled } from "styled-components";
import { useState } from "react";

export type DropdownOption = {
  value: string;
  label: string;
};

type AppDropdownSingleProps = {
  options: DropdownOption[];
  id: string;
  placeholder?: string;
  onChange: (value: any) => void;
  error?: string;
  value: any;
  label?: string;
  disabled?: boolean;
};

export const AppDropdownSingleWrapper = styled.div`
  position: relative;
`;

const AppDropdownLabel = styled(AppLabel)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const AppDropdownSingle = (props: AppDropdownSingleProps) => {
  const [labelHidden, setLabelHidden] = useState(false);

  return (
    <AppDropdownSingleWrapper>
      <Select
        options={props.options}
        styles={{
          control: (provided) => ({
            ...provided,
            border: "none",
            borderBottom: "0.5px solid #949494",
            borderRadius: 0,
            textAlign: "right",
            minHeight: 50,
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
        }}
        placeholder={props.placeholder || "Select"}
        onChange={(x) => props.onChange(x?.value)}
        onFocus={() => setLabelHidden(true)}
        onBlur={() => setLabelHidden(false)}
        value={props.value}
        isDisabled={props.disabled}
      />
      {props.label && !labelHidden && (
        <AppDropdownLabel>{props.label}</AppDropdownLabel>
      )}
      {props.error && <ValidationError>{props.error}</ValidationError>}
    </AppDropdownSingleWrapper>
  );
};

const animatedComponents = makeAnimated();

type AppDropdownMultiProps = {
  options: DropdownOption[];
  selectedOptions: DropdownOption[];
  label?: string;
  onChange: (value: DropdownOption[]) => void;
  error?: string;
  disabled?: boolean;
};

export const AppDropdownMulti = (props: AppDropdownMultiProps) => {
  return (
    <div>
      {props.label && <AppLabel>{props.label}</AppLabel>}
      <Select
        defaultValue={props.selectedOptions}
        options={props.options}
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        styles={{
          control: (provided) => ({
            ...provided,
            border: "none",
            borderBottom: "0.5px solid #949494",
            borderRadius: 0,
            marginTop: `${12 / 16}rem`,
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
        }}
        isDisabled={props.disabled}
        onChange={(x: any) => props.onChange(x)}
      />
      {props.error && <ValidationError>{props.error}</ValidationError>}
    </div>
  );
};

// const AppDropdownSingleWrapper = styled.label`
//   color: var(--gray-gray-400, #949494);
//   font-family: CerebriSans;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
// `;
