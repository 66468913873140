import { useQuery } from "@apollo/client";
import { GET_CURRENCIES } from "../queries";
import { keyBy } from "lodash";

export const useCurrencies = () => {
  const { data } = useQuery<{
    currency_category: {
      code: string;
      symbol: string;
      name: string;
      fraction_size: number;
    }[];
  }>(GET_CURRENCIES);

  const currencies = (data?.currency_category || []).map((x) => ({
    code: x.code,
    name: x.name,
  }));

  if (currencies.length) {
    currencies?.sort((a, b) => a.name.localeCompare(b.name));
  }

  function getCurrencyByCode(code: string) {
    const currencyByCode = keyBy(currencies, "code");

    return currencyByCode[code];
  }

  return {
    currencies,
    getCurrencyByCode,
  };
};
