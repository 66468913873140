import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

export const AccountBlockerPending = () => {
  const { logout } = useAuth0();

  return (
    <AccountBlockerContainer>
      <IconWrapper>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4558_13932)">
            <path
              d="M28.0003 4.66667C15.1203 4.66667 4.66699 15.12 4.66699 28C4.66699 40.88 15.1203 51.3333 28.0003 51.3333C40.8803 51.3333 51.3337 40.88 51.3337 28C51.3337 15.12 40.8803 4.66667 28.0003 4.66667ZM28.0003 46.6667C17.7103 46.6667 9.33366 38.29 9.33366 28C9.33366 17.71 17.7103 9.33334 28.0003 9.33334C38.2903 9.33334 46.667 17.71 46.667 28C46.667 38.29 38.2903 46.6667 28.0003 46.6667ZM38.7103 17.6867L23.3337 33.0633L17.2903 27.0433L14.0003 30.3333L23.3337 39.6667L42.0003 21L38.7103 17.6867Z"
              fill="#54A766"
            />
          </g>
          <defs>
            <clipPath id="clip0_4558_13932">
              <rect width="56" height="56" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </IconWrapper>
      <Heading>Your account is pending for review</Heading>
      <Description>
        Your will be informed via email as soon as the account is approved.
      </Description>
      <Redirection>
        Back to{" "}
        <a
          href="#"
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          }
        >
          Sign in
        </a>
      </Redirection>
    </AccountBlockerContainer>
  );
};

export const AccountBlockerAvailable = () => {
  const { logout } = useAuth0();

  return (
    <AccountBlockerContainer>
      <IconWrapper>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="56" height="56" rx="28" fill="#FFD1D1" />
          <path
            d="M24.3231 22.3432C23.7763 21.7964 22.8899 21.7964 22.3432 22.3432C21.7964 22.8899 21.7964 23.7763 22.3432 24.3231L26.0199 27.9998L22.3432 31.6765C21.7964 32.2232 21.7964 33.1097 22.3432 33.6564C22.8899 34.2031 23.7763 34.2031 24.3231 33.6564L27.9998 29.9797L31.6765 33.6564C32.2232 34.2031 33.1097 34.2031 33.6564 33.6564C34.2031 33.1097 34.2031 32.2232 33.6564 31.6765L29.9797 27.9998L33.6564 24.3231C34.2031 23.7763 34.2031 22.8899 33.6564 22.3432C33.1097 21.7964 32.2232 21.7964 31.6765 22.3432L27.9998 26.0199L24.3231 22.3432Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.9999 5.60034C15.6287 5.60034 5.59991 15.6292 5.59991 28.0003C5.59991 40.3715 15.6287 50.4003 27.9999 50.4003C40.3711 50.4003 50.3999 40.3715 50.3999 28.0003C50.3999 15.6292 40.3711 5.60034 27.9999 5.60034ZM8.39991 28.0003C8.39991 17.1756 17.1751 8.40034 27.9999 8.40034C38.8247 8.40034 47.5999 17.1756 47.5999 28.0003C47.5999 38.8251 38.8247 47.6003 27.9999 47.6003C17.1751 47.6003 8.39991 38.8251 8.39991 28.0003Z"
            fill="white"
          />
        </svg>
      </IconWrapper>
      <Heading>Your account is Not Available</Heading>
      <Description>
        If you have any questions relating to the account, please reach us at
        <a href="#"> hello@kols.asia</a>
      </Description>
      <Redirection>
        Back to{" "}
        <a
          href="#"
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          }
        >
          Sign in
        </a>
      </Redirection>
    </AccountBlockerContainer>
  );
};

const AccountBlockerContainer = styled.div`
  height: 100%;
  width: 70%;
  min-width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 72px;
`;

const Heading = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
`;

const Redirection = styled.p`
  margin-bottom: 32px;

  & a {
    color: #7b73f4;
    text-decoration: none;
  }
`;

const Description = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 32px;

  & a {
    color: #7b73f4;
    text-decoration: none;
  }
`;
