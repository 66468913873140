import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  query GetCountryCat {
    country_category {
      code
      name
      phone_code
      is_active
    }
  }
`;
