import { gql } from "@apollo/client";

export const SAVE_KOL_PROFILE = gql`
  mutation AddKolProfile(
    $gender: String!
    $date_of_birth: date!
    $country_code: String!
    $display_currency: String!
    $individual_fullname: String!
    $individual_id_no: String!
    $bank_name: String!
    $bank_country: String!
    $bank_city: String!
    $bank_state: String!
    $bank_address: String!
    $bank_account_no: String!
    $first_name: String!
    $last_name: String!
    $bank_swift_code: String!
  ) {
    insert_kol_profile_one(
      object: {
        gender: $gender
        date_of_birth: $date_of_birth
        country_code: $country_code
        display_currency: $display_currency
        individual_fullname: $individual_fullname
        # product_categories: { data: { kol_id: "", interest_code: "" } }
        individual_id_no: $individual_id_no
        bank_name: $bank_name
        bank_country: $bank_country
        bank_city: $bank_city
        bank_state: $bank_state
        bank_address: $bank_address
        bank_account_no: $bank_account_no
        bank_swift_code: $bank_swift_code
        first_name: $first_name
        last_name: $last_name
      }
    ) {
      user_id
      gender
      date_of_birth
      country_code
      display_currency
      individual_fullname
      individual_id_no
      bank_name
      bank_country
      bank_city
      bank_state
      bank_address
      bank_account_no
      bank_swift_code
      id
      first_name
      last_name
    }
  }
`;

export const FETCH_KOL_PROFILE = gql`
  query GetKolProfile($user_id: uuid!) {
    kol_profile(where: { user_id: { _eq: $user_id } }) {
      id
      address
      address_city
      address_postal_code
      address_state
      bank_account_no
      bank_address
      bank_branch_full_address
      bank_city
      bank_country
      bank_name
      bank_state
      bank_swift_code
      bio
      company_name
      company_registration_no
      country_code
      cpe_rate
      created_at
      date_of_birth
      display_currency
      email
      engagement_rate
      entity_type
      first_name
      follower_size
      gender
      individual_fullname
      individual_id_no
      individual_passport_no
      is_active
      is_approved
      last_name
      min_rate_photo_usd_cents
      min_rate_video_usd_cents
      mobile_number
      occupation
      past_brands_collaboration
      updated_at
      user_id
      wallet_id
      mobile_country_code
      bank_verification_status
      product_categories {
        interest_code
      }
      kol_file_metadata {
        type
        file_metadata {
          id
          url
          original_name
          owner_id
        }
        kol_profile_id
        created_at
      }
      user {
        email
      }
      mapping_kol_profile_languages {
        language {
          title
          code
        }
      }
      social_platforms {
        id
        social_platform_code
        platform_user_id
        platform_username
      }
      currency_category {
        code
        symbol
        fraction_size
        name
        template
      }
    }
  }
`;

export const UPDATE_KOL_PROFILE = gql`
  mutation updateKolProfile($id: uuid!, $objects: kol_profile_set_input) {
    update_kol_profile_by_pk(pk_columns: { id: $id }, _set: $objects) {
      id
      address
      address_city
      address_postal_code
      address_state
      bank_account_no
      bank_address
      bank_branch_full_address
      bank_city
      bank_country
      bank_name
      bank_state
      bank_swift_code
      bio
      company_name
      company_registration_no
      country_code
      cpe_rate
      created_at
      date_of_birth
      display_currency
      email
      engagement_rate
      entity_type
      first_name
      follower_size
      gender
      individual_fullname
      individual_id_no
      individual_passport_no
      is_active
      is_approved
      last_name
      min_rate_photo_usd_cents
      min_rate_video_usd_cents
      mobile_number
      mobile_country_code
      occupation
      past_brands_collaboration
      updated_at
      user_id
      wallet_id
      product_categories {
        interest_code
      }
      kol_file_metadata {
        type
        file_metadata {
          id
          url
          original_name
          owner_id
        }
        kol_profile_id
      }
      user {
        email
      }
      mapping_kol_profile_languages {
        language {
          title
          code
        }
      }
    }
  }
`;
